import React, { useCallback, useMemo } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select';

const ExcludeCreatives = ({ formData, company, setFormData, additional }) => {
    const creativeOptions = useMemo(() => (
        additional?.creative?.map(creativ => ({ value: creativ, label: creativ })) || []
    ), [additional]);

    const handleSelectChange = useCallback((selectedOptions, name) => {
        setFormData(prev => ({
            ...prev,
            [name]: selectedOptions.map(option => option.value)
        }));
    }, [setFormData]);

    return (
        <div>
            <Row className="mt-3">
                <Col xs={12}>
                    <Form.Group controlId="formCreative">
                        <Form.Label className="text-secondary">Select Creative to Exclude from the Category Table</Form.Label>
                        <Select
                            isMulti
                            options={creativeOptions}
                            value={creativeOptions.filter(option =>
                                formData?.creativeFilter?.includes(option?.value)
                            )}
                            onChange={selectedOptions => handleSelectChange(selectedOptions, 'creativeFilter')}
                            closeMenuOnSelect={false}
                        />
                    </Form.Group>
                </Col>
            </Row>
        </div>
    )
}

export default ExcludeCreatives;
