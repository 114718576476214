import React from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'

const ExtraColumnTable = ({ formData, attributeOptions, columnValueOptions, handleSelectColumnsChange, selectedBudget, company, setFormData }) => {
    console.log("FormData at render:", formData.extraTableColumn);
    const handleAddColumn = () => {
        const newColumn = { label: '', name: '', value: '', select: 'count' };
        setFormData((prev) => ({
            ...prev,
            extraTableColumn: [...prev.extraTableColumn, newColumn],
        }));
    };
    const handleSelectChangeOption = (index, value) => {
        const updatedColumns = [...formData.extraTableColumn];
        updatedColumns[index].select = value;
        setFormData(prev => ({
            ...prev,
            extraTableColumn: updatedColumns,
        }));
    };

    const handleLabelChange = (index, value) => {
        const updatedColumns = [...formData.extraTableColumn];
        updatedColumns[index].label = value;
        setFormData(prev => ({
            ...prev,
            extraTableColumn: updatedColumns,
        }));
    };

    return (
        <div className="mt-3">
            <p className="text-secondary form-label mb-0 pb-2">Extra Columns</p>
            <div className=''>
                <div className='border py-2 '>
                    {formData?.extraTableColumn?.map((column, index) => (
                        <div className="px-2 py-2" key={index}>
                            <Row className="gy-2 align-items-center" key={index}>
                                <Form.Label className=" mb-0">Column {index + 1}</Form.Label>
                                <Col sm={6}>
                                    <Form.Group controlId={`Matric-${index}`}>
                                        <Form.Label className="text-secondary mb-0"> Label {index + 1}</Form.Label>
                                        <Form.Control
                                            type='text'
                                            name={`column-${index}-label`}
                                            value={formData.extraTableColumn[index].label}
                                            onChange={e => handleLabelChange(index, e.target.value)}
                                            placeholder={`Label-${index + 1}`}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group controlId={`formAttribute-${index}`}>
                                        <Form.Label className="text-secondary  mb-0"> Label {index + 1} Column</Form.Label>
                                        <Form.Select value={formData?.extraTableColumn[index]?.name} onChange={e => handleSelectColumnsChange(index, 'name', e.target.value)}>
                                            <option value="">Select Column</option>
                                            {attributeOptions.map(attr => (
                                                <option key={attr.value} value={attr.value}>{attr.label}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group controlId={`formValue-${index}`}>
                                        <Form.Label className="text-secondary  mb-0"> Label {index + 1} Value</Form.Label>
                                        <Form.Select value={formData?.extraTableColumn[index]?.value} onChange={e => handleSelectColumnsChange(index, 'value', e.target.value)} disabled={!column.name}>
                                            <option value="">Select Value</option>
                                            {columnValueOptions[index]?.map(val => (
                                                <option key={val.value} value={val.value}>{val.label}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group controlId={`selectOption-${index}`}>
                                        <Form.Label className="text-secondary mb-0"> Label {index + 1} Option</Form.Label>
                                        <Form.Select value={formData?.extraTableColumn[index]?.select} onChange={e => handleSelectChangeOption(index, e.target.value)}>
                                            <option value="count">Count</option>
                                            <option value="sum">Sum</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                    ))}
                    <Button
                        style={{ backgroundColor: 'var( --color1)', color: 'var( --color2)' }}
                        variant="primary"
                        onClick={handleAddColumn}
                        className="m-3 company-btn-hover border-0"
                    >
                        Add Column
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default ExtraColumnTable
