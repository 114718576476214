import { useEffect, useState } from 'react';
import { Col, Container, Row, Dropdown } from 'react-bootstrap';
import { DashboardColors } from '../utils/Static';
import Pie from '../components/charts/Pie';
import Bar from '../components/charts/Bar';
import ChartHeading from '../components/shared/ChartHeading';
import CustomCard from './PdfCustomCard';
import DashboardTable from '../components/shared/Table';
import TableHeading from '../components/shared/TableHeading';
import Pagination from '../components/shared/TablePagination';
import { getCategoryChartDataPie, getCategoryTable, getChartData, getStatsData } from '../services/adminService/adminService';
import PerPieChart from '../components/charts/PerPieChart';
import PdfHeader from '../components/shared/PdfHeader';
import PdfFooter from '../components/shared/PdfFooter';

const PdfSite = ({ selectedRange, showImage }) => {
    const [companyId, setCompanyId] = useState(localStorage.getItem('selectedCompany'));
    const [currentPage, setCurrentPage] = useState(1);
    const [tableData, setTableData] = useState([]);
    const [stats, setStats] = useState(null);
    const [loading, setLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(true);
    const [chartData, setChartData] = useState(null);
    const [ftdShow, setFtdSow] = useState();
    const [appIdShow, setAppId] = useState();
    const [pieChartLoading, setPieChartLoading] = useState(true);
    const [pieChartData, setPieChartData] = useState([]);
    const [pieChartPercentage, setPieChartPercentage] = useState(null);
    const [chartLoading, setChartLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(5);
    const [selectedCategories, setSelectedCategories] = useState([]);
    // const [dropdownOpen, setDropdownOpen] = useState(false); // Control dropdown open state
    const DashboardtableHeadings = [
        "RANK",
        "APP ID",
        "CATEGORY",
        tableData?.length > 0 ? Object?.keys(tableData[0])[3] : "FTD’s (unique)",
        tableData?.length > 0 ? `${Object?.keys(tableData[0])[4]}` : "%FTD’s (unique)",
    ];
    const DashboardtableHeadings2 = [
        "Rank",
        "CATEGORY",
        tableData?.length > 0 ? Object?.keys(tableData[0])[2] : "FTD’s (unique)",
        tableData?.length > 0 ? `${Object?.keys(tableData[0])[3]}` : "%FTD’s (unique)",
    ];
    const DashboardtableHeadings3 = [
        "RANK",
        "APP ID",
        "CATEGORY",
        tableData?.length > 0 ? `${Object?.keys(tableData[0])[3]}` : "%FTD’s (unique)",
    ];
    const DashboardtableHeadings4 = [
        "RANK",
        "CATEGORY",
        tableData?.length > 0 ? `${Object?.keys(tableData[0])[2]}` : "%FTD’s (unique)",
    ];
    const tableHeadings = () => {
        if (appIdShow && ftdShow) return DashboardtableHeadings;
        if (appIdShow) return DashboardtableHeadings3;
        if (ftdShow) return DashboardtableHeadings2;
        return DashboardtableHeadings4;
    };
    const tableBody = tableData?.map((data) => {
        let index;
        if (appIdShow && ftdShow) {
            index = 3;
        } else if (appIdShow && !ftdShow) {
            index = 3;
        } else if (!appIdShow && ftdShow) {
            index = 2;
        } else {
            index = 2;
        }

        const row = [
            data?.Rank,
            ...(appIdShow ? [data?.APP_ID] : []),
            data?.CategoryName,
            ...(ftdShow ? [Object?.values(data)[index]] : []),
            ...(ftdShow ? [`${Object?.values(data)[index + 1]}%`] : [`${Object?.values(data)[index]}%`]),
        ];

        return row.filter(value => value != null);
    }) || [];

    const dashboardCards = stats?.dynamicResults
        ? Object.entries(stats.dynamicResults).map(([label, value], index) => ({
            label: label,
            value: index === 0
                ? `${new Intl.NumberFormat('fr-FR').format(value)} ${stats?.currency || ''}`
                : `${new Intl.NumberFormat('fr-FR').format(value)}`,
        }))
        : [];

    const categories = pieChartData?.map(item => item.name);

    useEffect(() => {
        if (pieChartData?.length > 0) {
            setSelectedCategories(categories);
        }
        // eslint-disable-next-line
    }, [pieChartData]);

    // const filteredPieChartData = pieChartData.filter(item => selectedCategories.includes(item.name));

    const getTable = async (page, formattedStartDate, formattedEndDate) => {
        try {
            setTableLoading(true);
            const response = await getCategoryTable(formattedStartDate, formattedEndDate, page, 7, companyId);
            console.log("Table", response?.data?.data?.data)
            setTableData(response?.data?.data?.data);
            setTotalPages(response?.data?.data?.totalPages);
            setFtdSow(response?.data?.data?.ftdEnabled)
            setAppId(response?.data?.data?.appIdEnabled)
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setTableLoading(false);
        } finally {
            setTableLoading(false);
        }
    };

    const getStats = async (formattedStartDate, formattedEndDate) => {
        try {
            setLoading(true);
            const response = await getStatsData(formattedStartDate, formattedEndDate, companyId);
            setStats(response?.data?.data);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const getChart = async (formattedStartDate, formattedEndDate) => {
        try {
            setChartLoading(true);
            const response = await getChartData(formattedStartDate, formattedEndDate, companyId);
            setChartData(response?.data?.data);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setChartLoading(false);
        } finally {
            setChartLoading(false);
        }
    };

    const getPieChart = async (formattedStartDate, formattedEndDate) => {
        try {
            setPieChartLoading(true);
            const response = await getCategoryChartDataPie(formattedStartDate, formattedEndDate, companyId);
            setPieChartData(response?.data?.data?.dashboardPie);
            setPieChartPercentage(response?.data?.data?.percDiffer)
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setPieChartLoading(false);
        } finally {
            setPieChartLoading(false);
        }
    };
    useEffect(() => {
        console.log("Company Id again", companyId)

        const handleCompanyChange = () => {
            setCompanyId(localStorage.getItem('selectedCompany'));
        };
        window.addEventListener('user-update', handleCompanyChange);

        return () => {
            window.removeEventListener('user-update', handleCompanyChange);
        };
    }, [companyId]);
    useEffect(() => {
        if (selectedRange && selectedRange?.length === 2) {
            setCurrentPage(1);
            const [startDate, endDate] = selectedRange;
            const formatLocalDate = (date) => {
                return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
            };
            const formattedStartDate = formatLocalDate(startDate);
            const formattedEndDate = formatLocalDate(endDate);
            getStats(formattedStartDate, formattedEndDate)
            getChart(formattedStartDate, formattedEndDate)
            getPieChart(formattedStartDate, formattedEndDate)
        }
    }, [selectedRange, companyId]);

    useEffect(() => {
        if (selectedRange && selectedRange?.length === 2) {
            const [startDate, endDate] = selectedRange;
            const formatLocalDate = (date) => {
                return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
            };
            const formattedStartDate = formatLocalDate(startDate);
            const formattedEndDate = formatLocalDate(endDate);
            getTable(currentPage, formattedStartDate, formattedEndDate)
        }
    }, [selectedRange, currentPage, companyId]);

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    // const toggleCategory = (category) => {
    //     setSelectedCategories((prevSelected) =>
    //         prevSelected.includes(category)
    //             ? prevSelected.filter((cat) => cat !== category)
    //             : [...prevSelected, category]
    //     );
    // };

    // const handleDropdownToggle = (isOpen) => {
    //     setDropdownOpen(isOpen);
    // };

    return (
        <div id="pdf" className='pdf-size'>
            <div style={{
                backgroundImage: showImage
                    ? "url('https://res.cloudinary.com/djmk3gpgr/image/upload/v1730396033/skhbjvc4cy1zxtzudjvm.png')"
                    : "none",
                backgroundSize: 'cover',
                backgroundRepeat: "no-repeat",
                backgroundPosition: 'top center'
            }}
            >
                <PdfHeader showImage={showImage} />
                <div className={`mb-5 ${showImage ? 'px-5' : ''}`} fluid>
                    <CustomCard data={dashboardCards} loading={loading} />
                    <Row>
                        <Col xs={8}>
                            <div className='bg-white rounded-4 p-4 my-4'>
                                <ChartHeading heading={"Performances"} />
                                <div className="d-none d-block mb-4"></div>
                                <Bar data={chartData} loading={chartLoading} />
                            </div>
                        </Col>
                        <Col xs={4}>
                            <div className='bg-white rounded-4 p-4 my-4'>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <ChartHeading heading={"Category Selection"} />
                                </div>
                                <PerPieChart textWidth={"80%"} height={400} width={400} outerRadius={"60%"} innerRadius={"40%"} COLORS={DashboardColors} data={pieChartData} loading={pieChartLoading} pieChartPercentage={pieChartPercentage} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className={`${showImage ? 'pb-5' : ''}`}>
                            <div className={`p-5 bg-white rounded-4 m-1 ${showImage ? '' : ''}`}>
                                <div className='d-flex justify-content-between align-items-center mb-5'>
                                    <TableHeading heading={"By Campaign"} />
                                    <Pagination
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        onNext={handleNext}
                                        onPrevious={handlePrevious}
                                    />
                                </div>
                                <DashboardTable
                                    tableBody={tableBody}
                                    tableHeadings={tableHeadings()}
                                    loading={tableLoading}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <PdfFooter showImage={showImage} />
        </div>
    );
};

export default PdfSite;
