import React, { useState, useMemo } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { addUsersToCompany } from '../../utils/admin/admin.service';

const CompanyUserAdd = ({ reCall, companyId, show, close, usersOption, users }) => {
    const [selectedUsers, setSelectedUsers] = useState(users);

    const handleSelectChange = (selected) => {
        setSelectedUsers(selected);
    };

    const availableOptions = useMemo(() => {
        const companyUserEmails = users.map(user => user.email);
        return usersOption.filter(option => !companyUserEmails.includes(option.email));
    }, [users, usersOption]);

    const handleAddUsers = async () => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to add these users to the company.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#1cc76d",
            cancelButtonColor: "#6C757D",
            confirmButtonText: "Yes, add them!",
            cancelButtonText: "No, cancel",
            allowOutsideClick: false,
            preConfirm: () => {
                Swal.showLoading();
                return new Promise(async (resolve, reject) => {
                    try {
                        const userIds = selectedUsers.map((user) => user._id);
                        await addUsersToCompany(companyId, userIds);
                        resolve();
                    } catch (error) {
                        reject(error?.response?.data?.error || 'Network Error');
                    }
                });
            }
        })
            .then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: "Added!",
                        text: "Users have been added to the company.",
                        icon: "success",
                        confirmButtonColor: "#28a745",
                    });
                    close();
                    setSelectedUsers([])
                    reCall();
                }
            })
            .catch((error) => {
                Swal.fire({
                    title: "Error!",
                    text: error || 'An error occurred while creating the user.',
                    icon: "error",
                    confirmButtonColor: "#dc3545",
                });
            });
    };
    return (
        <Modal show={show} onHide={close} centered className='py-0 my-0' keyboard={false} backdrop="static">
            <Modal.Header className='border-0' closeButton >
                <Modal.Title className='text-box' style={{ color: "var(--primary-color)" }}>Add Users to Company</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Select Users to Add</Form.Label>
                        <Select
                            isMulti
                            options={availableOptions}
                            value={selectedUsers}
                            onChange={handleSelectChange}
                            getOptionLabel={(option) => option.email}
                            getOptionValue={(option) => option.email}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between pt-2">
                <Button onClick={close} className='bg-secondary border-0 px-4 py-2' >Cancel</Button>
                <Button type="button" className='bg-primary-ltr border-0 px-4 py-2 submit-btn-hover' onClick={handleAddUsers}>
                    Add
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CompanyUserAdd;
