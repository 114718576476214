import React, { useState } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { Button } from "react-bootstrap";
import Skeleton from 'react-loading-skeleton';

const CustomPie = ({ data, innerRadius, outerRadius, height, width, loading }) => {
    const processedData = data?.map(entry => ({
        name: entry?.name,
        value: Number(entry?.value)
    })) || [];

    const totalValue = processedData.reduce((sum, entry) => sum + entry.value, 0);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 4;
    const totalPages = Math.ceil(processedData?.length / itemsPerPage);

    // Find the maximum value in the processedData
    const maxValue = Math.max(...processedData.map(entry => entry.value));

    const renderTooltip = ({ active, payload }) => {
        if (active && payload && payload?.length) {
            const { name, value } = payload[0];
            return (
                <div className="custom-tooltip" style={{
                    backgroundColor: '#fff',
                    border: '1px solid #ccc',
                    padding: '10px',
                    borderRadius: '5px',
                    wordWrap: 'break-word',
                    maxWidth: '140px',
                    whiteSpace: 'normal',
                }}>
                    <p className="mb-0" style={{ fontSize: '12px', fontWeight: 'bold', marginBottom: '5px' }}>{name}</p>
                    <p className="mb-0" style={{ fontSize: '12px' }}>{value}%</p>
                </div>
            );
        }
        return null;
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = processedData.slice(startIndex, endIndex);

    const onPrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const onNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <div className="d-flex justify-content-center flex-column align-items-center overflow-hidden">
            {loading ? (
                <Skeleton className="mb-3" height={height || "50%"} width={width || "90%"} />
            ) : totalValue > 0 ? ( // Check if totalValue is greater than 0
                <ResponsiveContainer width={width || "90%"} height={height || "60%"}>
                    <PieChart>
                        <Pie
                            data={processedData}
                            cx="50%"
                            cy="50%"
                            innerRadius={innerRadius || 100}
                            outerRadius={outerRadius || 170}
                            fill="#8884d8"
                            labelLine={false}
                            dataKey="value"
                        >
                            {processedData.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={entry.value === maxValue ? '#ffe521' : '#007755'} // Conditional color assignment
                                />
                            ))}
                        </Pie>
                        <Tooltip content={renderTooltip} />
                    </PieChart>
                </ResponsiveContainer>
            ) : (
                <p className="text-center pt-5" style={{ height: height, width: width }}>No Data Available</p> // Show message when totalValue is 0
            )}

            <div className="container-fluid p-3" style={{ backgroundColor: "#f8f9fa", borderRadius: "8px", width: "90%" }}>
                {loading ? (
                    Array.from({ length: 3 }).map((_, index) => (
                        <Skeleton key={`skeleton-${index}`} height={30} style={{ marginBottom: '5px' }} />
                    ))
                ) : processedData?.length === 0 ? (
                    <p className="text-center">No Data Available</p>
                ) : (
                    currentItems.map((entry, index) => (
                        <div key={`legend-${index}`} className="d-flex justify-content-between align-items-center mb-2" style={{ padding: "5px 0", borderBottom: "1px solid #ddd" }}>
                            <div className="d-flex align-items-center">
                                <div style={{ width: "3px", height: "20px", backgroundColor: entry.value === maxValue ? '#ffe521' : '#007755', marginRight: "10px" }} />
                                <p className="mb-0 fs-12">{entry.name}</p>
                            </div>
                            <div className="text-end fs-12">{entry.value}%</div>
                        </div>
                    ))
                )}
                {totalPages > 1 && processedData?.length > 0 && (
                    <div className="d-flex gap-3 justify-content-end mt-2">
                        <Button className='btn-custom rounded-3 small p-1 px-2' onClick={onPrevious} disabled={currentPage === 1}>
                            <FaAngleLeft />
                        </Button>
                        <Button className='btn-custom rounded-3 small p-1 px-2' onClick={onNext} disabled={currentPage === totalPages}>
                            <FaAngleRight />
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CustomPie;
