import React, { useCallback, useMemo } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';

const AttributeTouchSelection = ({ formData, setFormData, attributeOptions, handleAttributeTouch, valueOptionsAttribute }) => {
    const attributeTouchOptions = useMemo(() => (
        [{ value: 'all', label: 'Select All' }, ...(valueOptionsAttribute?.map(attr => ({ value: attr.value, label: attr.label })) || [])]
    ), [valueOptionsAttribute]);

    const handleSelectChange = useCallback((selectedOptions) => {
        const isSelectAll = selectedOptions.some(option => option.value === 'all');
        if (isSelectAll) {
            setFormData(prev => ({
                ...prev,
                attributeTouch: {
                    ...prev.attributeTouch,
                    value: attributeTouchOptions.slice(1).map(option => option.value)
                }
            }));
        } else {
            setFormData(prev => ({
                ...prev,
                attributeTouch: {
                    ...prev.attributeTouch,
                    value: selectedOptions.map(option => option.value)
                }
            }));
        }
    }, [attributeTouchOptions, setFormData]);

    return (
        <div className="mt-3">
            <p className="form-label text-secondary pb-2">Select Event Columns to Show in Attribute Touch Graph</p>
            <div className="px-2">
                <Row className="border py-2 gy-2">
                    <Col sm={6}>
                        <Form.Group controlId={`Attr label`}>
                            <Form.Label className="text-secondary mb-0">Label</Form.Label>
                            <Form.Control
                                type="text"
                                name={`Attr-label`}
                                value={formData?.attributeTouch?.label}
                                onChange={(e) => {
                                    setFormData(prev => ({
                                        ...prev,
                                        attributeTouch: {
                                            ...prev.attributeTouch,
                                            label: e.target.value
                                        }
                                    }));
                                }}
                            />

                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group controlId="formAttribute">
                            <Form.Label className="text-secondary mb-0">Select Column name</Form.Label>
                            <Form.Select value={formData?.attributeTouch?.name} onChange={handleAttributeTouch}>
                                <option value="">Select Column</option>
                                {attributeOptions?.map(attr => (
                                    <option key={attr.value} value={attr.value}>{attr.label}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col sm={12}>
                        <Form.Group controlId="formValue">
                            <Form.Label className="text-secondary mb-0">Select Column value</Form.Label>
                            <Select
                                isMulti
                                options={attributeTouchOptions}
                                value={attributeTouchOptions?.filter(option => formData?.attributeTouch?.value?.includes(option?.value))}
                                onChange={selectedOptions => handleSelectChange(selectedOptions)}
                                closeMenuOnSelect={false}
                                aria-label="Attribute Touch Selection"
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default AttributeTouchSelection;
