// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rs-input-group-inside {
  margin-top: 2px;

  border: var(--color2) !important;
}
.rs-picker-input-group:focus-within {
  border-color: var(--color2) !important;
  outline: none !important;
  width: 100px !important;
}
.rs-date-range-input.rs-input,
.rs-input-group.rs-input-group-inside {
  border: 0px !important;
  background-color: #09332c !important;
  color: #ffffff !important;
}
.rs-input-group-addon {
  display: none !important;
}

.rs-picker-popup
  .rs-calendar
  .rs-calendar-table-cell-selected:hover
  .rs-calendar-table-cell-content,
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background: var(--color1) !important;
  color: var(--color2) !important;
}

.rs-calendar-table-cell-in-range .rs-calendar-table-cell-content,
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background: #efeac1 !important;
}
.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  box-shadow: var(--color2) !important;
}

.rs-btn-primary {
  background-color: var(--color1) !important;
  color: var(--color2) !important;
}
.rs-btn-link {
  color: var(--color2) !important;
}
.rs-picker-popup.rs-picker-popup-daterange {
  z-index: 999 !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/DateRange.css"],"names":[],"mappings":"AAAA;EACE,eAAe;;EAEf,gCAAgC;AAClC;AACA;EACE,sCAAsC;EACtC,wBAAwB;EACxB,uBAAuB;AACzB;AACA;;EAEE,sBAAsB;EACtB,oCAAoC;EACpC,yBAAyB;AAC3B;AACA;EACE,wBAAwB;AAC1B;;AAEA;;;;;EAKE,oCAAoC;EACpC,+BAA+B;AACjC;;AAEA;;EAEE,8BAA8B;AAChC;AACA;EACE,oCAAoC;AACtC;;AAEA;EACE,0CAA0C;EAC1C,+BAA+B;AACjC;AACA;EACE,+BAA+B;AACjC;AACA;EACE,uBAAuB;AACzB","sourcesContent":[".rs-input-group-inside {\n  margin-top: 2px;\n\n  border: var(--color2) !important;\n}\n.rs-picker-input-group:focus-within {\n  border-color: var(--color2) !important;\n  outline: none !important;\n  width: 100px !important;\n}\n.rs-date-range-input.rs-input,\n.rs-input-group.rs-input-group-inside {\n  border: 0px !important;\n  background-color: #09332c !important;\n  color: #ffffff !important;\n}\n.rs-input-group-addon {\n  display: none !important;\n}\n\n.rs-picker-popup\n  .rs-calendar\n  .rs-calendar-table-cell-selected:hover\n  .rs-calendar-table-cell-content,\n.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {\n  background: var(--color1) !important;\n  color: var(--color2) !important;\n}\n\n.rs-calendar-table-cell-in-range .rs-calendar-table-cell-content,\n.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {\n  background: #efeac1 !important;\n}\n.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {\n  box-shadow: var(--color2) !important;\n}\n\n.rs-btn-primary {\n  background-color: var(--color1) !important;\n  color: var(--color2) !important;\n}\n.rs-btn-link {\n  color: var(--color2) !important;\n}\n.rs-picker-popup.rs-picker-popup-daterange {\n  z-index: 999 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
