import Home from "../../assets/svg/home.svg";
import Metrics from "../../assets/svg/metrics.svg";
import Creatives from "../../assets/svg/pencil.svg";
import Site from "../../assets/svg/site.svg";
import Geo from "../../assets/svg/geo.svg";
import perDay from "../../assets/svg/perDay.svg"
export const SidebarMenus = [
    {
        visibleOn: ["user"],
        icon: Home,
        path: "/overview",
        label: "Overview",
    },
    {
        visibleOn: ["user"],
        icon: perDay,
        path: "/overview-per-day",
        label: "Overview Per Day",
    },
    {
        visibleOn: ["user"],
        icon: Metrics,
        path: "/metrics",
        label: "Metrics",
    },
    {
        visibleOn: ["user"],
        icon: Creatives,
        path: "/creatives",
        label: "Creatives",
    },
    {
        visibleOn: ["user"],
        icon: Site,
        path: "/placements",
        label: "Placements",
    },
    {
        visibleOn: ["user"],
        icon: Geo,
        path: "/geo",
        label: "Locations",
    },
    {
        visibleOn: ["admin"],
        icon: Metrics,
        path: "/dashboard",
        label: "Company",
    },
    {
        visibleOn: ["admin"],
        icon: Creatives,
        path: "/user-management",
        label: "User management",
    },
];
export const PdfMenus = [
    {
        visibleOn: ["user"],
        icon: Home,
        path: "/overview-pdf",
        label: "Overview",
    },
    {
        visibleOn: ["user"],
        icon: perDay,
        path: "/overview-per-day-pdf",
        label: "Overview Per Day",
    },
    {
        visibleOn: ["user"],
        icon: Metrics,
        path: "/metrics-pdf",
        label: "Metrics",
    },
    {
        visibleOn: ["user"],
        icon: Creatives,
        path: "/creatives-pdf",
        label: "Creatives",
    },
    {
        visibleOn: ["user"],
        icon: Site,
        path: "/placements-pdf",
        label: "Placements",
    },
    {
        visibleOn: ["user"],
        icon: Geo,
        path: "/locations-pdf",
        label: "Locations",
    },
    {
        visibleOn: ["admin"],
        icon: Metrics,
        path: "/dashboard-pdf",
        label: "Company",
    },
    {
        visibleOn: ["admin"],
        icon: Creatives,
        path: "/user-management-pdf",
        label: "User management",
    },
];
