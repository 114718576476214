import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React, { useEffect, useState } from 'react';
import { Spinner, Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { PdfMenus } from './components/shared/MenuItems';
import { FaDownload } from 'react-icons/fa';
import { useModal } from './utils/Modal';
import { PiDownloadSimpleBold } from 'react-icons/pi';
import { BiDownload } from 'react-icons/bi';
import soraFont from '../src/components/fonts/Sora-Light.ttf';

const GeneratePdf = ({ setShowImage, setProgress, selectedRange }) => {
  const [user, setUser] = useState(() => JSON.parse(localStorage.getItem("user")) || {});
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [isProcessing, setIsProcessing] = useState(false);
  const { showModal, setShowModal } = useModal();

  const usermenu = [
    { visibleOn: ['user'], path: '/start-pdf', label: '' },
    ...PdfMenus,
    { visibleOn: ['user'], path: '/end-pdf', label: '' },
  ];

  const menus = usermenu?.filter(menu => menu.visibleOn.includes('user'));
  const userTabs = menus?.filter(menu => {
    if (!user?.overviewPerDay && menu.path === '/overview-per-day-pdf') return false;
    if (!user?.siteIDShow && menu.path === "/placements-pdf") return false;
    if (!user?.showgeo && menu.path === '/locations-pdf') return false;

    return true;
  });

  const loadImagesToBase64 = async (content) => {
    const imgElements = content.querySelectorAll('img');
    const promises = Array.from(imgElements).map(async (img) => {
      if (img.src.startsWith('http')) {
        try {
          const response = await fetch(img.src, { mode: 'cors' });
          if (!response.ok) throw new Error(`Failed to fetch image: ${img.src}`);
          const blob = await response.blob();
          const base64 = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
          });
          img.src = base64;
        } catch (error) {
          console.error('Image conversion failed:', error);
          img.alt = '[Image could not be loaded]';
        }
      }
    });
    await Promise.all(promises);
  };

  const captureTabsAndGeneratePdf = async () => {
    setShowImage(true);
    setIsProcessing(true);
    setShowModal(true);
    setProgress(5);

    const pdf = new jsPDF({
      unit: 'px',
      format: [635, 900]
    });
    pdf.addFileToVFS("Sora-Light.ttf", soraFont);
    pdf.addFont("Sora-Light.ttf", "Sora", "normal");
    pdf.setFont("Sora");
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();

    for (let i = 0; i < userTabs.length; i++) {
      const tab = userTabs[i];
      navigate(tab.path);
      await new Promise(resolve => setTimeout(resolve, 9000));

      const content = document.getElementById('pdf');
      if (!content) continue;

      await loadImagesToBase64(content);

      const contentRect = content.getBoundingClientRect();
      const contentHeight = contentRect.height;

      const canvasOptions = {
        useCORS: true,
        allowTaint: true,
        height: contentHeight,
        scale: 2,
      };
      const canvas = await html2canvas(content, canvasOptions);
      const imgData = canvas.toDataURL('image/jpeg');

      const imgWidth = pageWidth;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      if (i > 0) pdf.addPage();

      let remainingHeight = imgHeight;
      let pageNumber = 1;

      while (remainingHeight > 0) {
        const printHeight = Math.min(remainingHeight, pageHeight);
        pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, printHeight);

        if (i !== 0 && remainingHeight > printHeight) {
          pdf.setFontSize(10);
          pdf.text("Copyright © 2024 KyPI", 10, pageHeight - 20);
          pdf.text(String(pageNumber).padStart(2, '0'), pageWidth - 20, pageHeight - 20);
          pageNumber++;
        }

        remainingHeight -= printHeight;
        if (remainingHeight > 0) pdf.addPage();
      }
      setProgress(5 + ((i + 1) / userTabs.length) * 95);
    }

    const fileName = `KyPI-Reporting-${user?.companyName}-${startDate}-${endDate}.pdf`;
    pdf.save(fileName);
    setIsProcessing(false);
    setShowModal(false);
    setShowImage(false);
    localStorage.removeItem('lastPageNumber');
    navigate('/overview');
  };


  useEffect(() => {
    const handleStorageChange = () => {
      setUser(JSON.parse(localStorage.getItem("user")) || {});
    };

    window.addEventListener('user-update', handleStorageChange);
    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('user-update', handleStorageChange);
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (selectedRange && selectedRange?.length === 2) {
      const [startDate, endDate] = selectedRange;
      const formatLocalDate = (date) => {
        return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
      };
      const formattedStartDate = formatLocalDate(startDate);
      const formattedEndDate = formatLocalDate(endDate);
      setStartDate(formattedStartDate);
      setEndDate(formattedEndDate);
    }
  }, [selectedRange]);
  return (
    <>
      {user?.showExport &&
        <>
          <div className="pdf-generator d-none d-lg-block">
            <Button
              className="company-btn-hover btn-sm d-flex align-items-center gap-1 border-0"
              style={{ backgroundColor: 'var( --color1)', color: 'var( --color2)' }}
              onClick={captureTabsAndGeneratePdf}
              disabled={isProcessing}
            >
              {isProcessing ? (
                <Spinner
                  className="spinner-border spinner-border-sm text-danger m-1 mx-2"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <FaDownload color="var(--color2)" />
              )}
            </Button>
          </div>
          <div className='d-lg-none d-block'>
            <Button
              className='bg-none pt-0 px-0 border-0'
              style={{ backgroundColor: 'transparent', paddingBottom: '3px' }}
              onClick={captureTabsAndGeneratePdf}
              disabled={isProcessing}
            >
              {isProcessing ? (
                <Spinner
                  className="spinner-border spinner-border-sm text-danger m-1 mx-2"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <BiDownload color="var(--color2)" size={20} />
              )}
            </Button>
          </div>
        </>
      }

    </>
  );
};

export default GeneratePdf;