import React, { useState } from 'react';
import { Card, Row, Col, Badge, Button } from 'react-bootstrap';
import EditCompanyModal from '../modals/EditCompanyModal';

const CompanyCardDetail = ({ company, reCall }) => {
    const [showEditModal, setShowEditModal] = useState(false);

    const handleEditClick = () => {
        setShowEditModal(true);
    };

    const handleModalClose = () => {
        setShowEditModal(false);
        // reCall();
    };
    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                <h4 style={{ color: '#4E4E4E' }} className="my-4">
                    Company Info
                </h4>
                <Button
                    className="btn-custom px-3 fw-bold d-flex align-items-center"
                    onClick={handleEditClick}
                    style={{ backgroundColor: "#007bff", borderColor: "#007bff" }}
                >
                    <span className="me-2">&#9998;</span>
                    Edit Company
                </Button>
            </div>
            <Card className="mb-4 boder-0 shadow" style={{ border: 'unset' }}>
                <Card.Body>
                    <Card className="mb-2 shadow rounded border-0" style={{ background: 'rgb(253 253 253)' }}>
                        <Card.Body>
                            <div className="d-flex justify-content-between align-items-center">
                                <Card.Text as="h3" className="fw-bold mb-0" style={{ color: '#4E4E4E' }} >
                                    {company?.name}
                                </Card.Text>
                                <Badge
                                    pill
                                    bg={company?.status ? 'success' : 'danger'}
                                    // className="px-3 py-2"
                                    style={{ fontSize: '1rem' }}
                                >
                                    {company?.status ? 'Active' : 'Inactive'}
                                </Badge>
                            </div>
                        </Card.Body>
                    </Card>
                    <Row className='p-4'>
                        <Card.Text as='h4' className="fw-bold text-secondary">Company Information</Card.Text>
                        <Col md={7} className="">
                            <div className='p-2'>
                                <Card.Text className='pb-0 mb-2 '>
                                    <strong className='info-head'>Address:</strong> <span className='text-secondary'> {company?.address || 'N/A'}</span>
                                </Card.Text>
                                <Card.Text className='pb-0 mb-2'>
                                    <strong className=' info-head'>Country:</strong><span className='text-secondary'> {company?.country || 'N/A'}</span>
                                </Card.Text>
                                <Card.Text className='pb-0 mb-2'>
                                    <strong className=' info-head'>Phone:</strong><span className='text-secondary'> {company?.phone || 'N/A'}</span>
                                </Card.Text>
                                <Card.Text className='pb-0 mb-2'>
                                    <strong className=' info-head'>Creative Url:</strong><span className='text-secondary'> {company?.creativeUrl || 'N/A'}</span>
                                </Card.Text>
                            </div>
                        </Col>
                        <Col md={5}>
                            <div className='p-2'>
                                <Card.Text className='pb-0 mb-2'>
                                    <strong className='info-head'>Main Sheet Id:</strong><span className='text-secondary'> {company?.sheetId || 'N/A'}</span>
                                </Card.Text>
                                <Card.Text className='pb-0 mb-2'>
                                    <strong className='info-head'>App Category Sheet Id:</strong> <span className='text-secondary'>{company?.categorySheetId || 'N/A'}</span>
                                </Card.Text>
                                <Card.Text>
                                    <strong className='info-head'>Creative Column Name:</strong><span className='text-secondary'> {company?.creativeColumn}</span>
                                </Card.Text>
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <EditCompanyModal
                show={showEditModal}
                onHide={handleModalClose}
                company={company}
                reCall={reCall}
            />
        </>
    );
};

export default CompanyCardDetail;
