import React from 'react'
import { Col, Form, Row } from 'react-bootstrap';

const BudgetSelection = ({ selectedBudget, setSelectedBudget, formData, handleAttributeChange, handleAttributeChange1budget2, handleAttributeChange2budget2, valueOptions, valueOptions1budget2, valueOptions2budget2, attributeOptions, setFormData }) => {
    return (
        <div className="mt-3">
            <div className="d-flex flex-md-row flex-column gap-md-4 gap-2 pb-md-2 pb-4">
                <p className="text-secondary form-label">Budget Calculation Formula</p>
                <Form.Group controlId="formSelectBudget">
                    <div className="d-flex align-items-center">
                        <Form.Check
                            type="radio"
                            id="budget1"
                            name="budget"
                            label="Budget 1"
                            value="budget"
                            checked={selectedBudget === "budget"}
                            onChange={(e) => setSelectedBudget(e.target.value)}
                            className="me-3"
                        />
                        <Form.Check
                            type="radio"
                            id="budget2"
                            name="budget"
                            label="Budget 2"
                            value="budget2"
                            checked={selectedBudget === "budget2"}
                            onChange={(e) => setSelectedBudget(e.target.value)}
                        />
                    </div>
                </Form.Group>
            </div>
            {selectedBudget === "budget" ? (
                <div className="px-2">
                    <Row className="border py-2 gy-2 justify-content-center">
                        <Col sm={6}>
                            <Form.Group controlId="formAttribute">
                                <Form.Label className="text-secondary mb-0">Select Column Name</Form.Label>
                                <Form.Select
                                    as="select"
                                    placeholder='Select Column'
                                    value={formData?.budget?.name}
                                    onChange={handleAttributeChange}
                                >
                                    <option value="">Select Column</option>
                                    {attributeOptions?.map(attr => (
                                        <option key={attr?.value} value={attr?.value}>{attr?.label}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group controlId="formValue">
                                <Form.Label className="text-secondary mb-0">Select Column Value</Form.Label>
                                <Form.Select as="select"
                                    placeholder='Select Column Value'
                                    value={formData?.budget?.value} onChange={e => setFormData(prev => ({
                                        ...prev, budget: { ...prev?.budget, value: e.target.value }
                                    }))} disabled={!formData?.budget?.name}>
                                    <option value="">Select Value</option>
                                    {valueOptions?.map(val => (
                                        <option key={val.value} value={val.value}>{val.label}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Row className="mt-2 justify-content-center align-items-center gy-2">
                            <Col sm={3}>
                                <p className="text-secondary text-md-end text-center form-label mb-0 pb-0">Multiply By(*)</p>
                            </Col>
                            <Col sm={3}>
                                <Form.Group controlId="formCount">
                                    <Form.Label className="text-secondary mb-0">Count</Form.Label>
                                    <Form.Control type="number" value={formData?.budget?.count} onChange={e => setFormData(prev => ({
                                        ...prev, budget: { ...prev?.budget, count: e.target.value }
                                    }))} />
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Group controlId="formCurrency">
                                    <Form.Label className="text-secondary mb-0">Select Currency</Form.Label>
                                    <Form.Select value={formData?.budget?.currency} onChange={e => setFormData(prev => ({
                                        ...prev, budget: { ...prev?.budget, currency: e.target.value }
                                    }))}>
                                        <option value="€">Euro</option>
                                        <option value="$">USD</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Group controlId={`selectOption-metric`}>
                                    <Form.Label className="text-secondary mb-0">Select Option</Form.Label>
                                    <Form.Select
                                        value={formData?.budget?.select}
                                        onChange={(e) => setFormData(prev => ({
                                            ...prev,
                                            budget: {
                                                ...prev.budget,
                                                select: e.target.value
                                            }
                                        }))}>
                                        <option value="count">Count</option>
                                        <option value="sum">Sum</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Row>
                </div>
            ) : (
                <div className="px-2">
                    <Row className="border py-2 gy-2 justify-content-center">
                        <Col sm={6}>
                            <Form.Group controlId="formAttribute1">
                                <Form.Label className="text-secondary mb-0">Select Column Name 1</Form.Label>
                                <Form.Select
                                    as="select"
                                    placeholder='Select Column'
                                    value={formData?.budget2?.name}
                                    onChange={handleAttributeChange1budget2}
                                >
                                    <option value="">Select Column</option>
                                    {attributeOptions?.map(attr => (
                                        <option key={attr?.value} value={attr?.value}>{attr?.label}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group controlId="formValue1">
                                <Form.Label className="text-secondary mb-0">Select Column Value 1</Form.Label>
                                <Form.Select as="select"
                                    placeholder='Select Column Value'
                                    value={formData?.budget2?.value} onChange={e => setFormData(prev => ({
                                        ...prev, budget2: { ...prev?.budget2, value: e.target.value }
                                    }))} disabled={!formData?.budget2?.name}>
                                    <option value="">Select Value</option>
                                    {valueOptions1budget2?.map(val => (
                                        <option key={val.value} value={val.value}>{val.label}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Row className="mt-2 justify-content-center align-items-center gy-2">
                            <Col sm={3}>
                                <p className="text-secondary text-md-end text-center form-label mb-0 pb-0">Multiply By(*)</p>
                            </Col>
                            <Col sm={3}>
                                <Form.Group controlId="formCount">
                                    <Form.Label className="text-secondary mb-0">Count </Form.Label>
                                    <Form.Control type="number" value={formData?.budget2?.count} onChange={e => setFormData(prev => ({
                                        ...prev, budget2: { ...prev?.budget2, count: e.target.value }
                                    }))} />
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group controlId={`selectOption-metric1`}>
                                    <Form.Label className="text-secondary mb-0">Select Option for Event Column1</Form.Label>
                                    <Form.Select
                                        value={formData?.budget2?.select1}
                                        onChange={(e) => setFormData(prev => ({
                                            ...prev,
                                            budget2: {
                                                ...prev.budget2,
                                                select1: e.target.value
                                            }
                                        }))}>
                                        <option value="count">Count</option>
                                        <option value="sum">Sum</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            {/* <Col sm={3}>
                                <p className="text-secondary text-md-start text-center form-label mb-0 pb-0">Adding In(+)</p>
                            </Col> */}
                        </Row>
                        <Row className="mt-2 justify-content-center align-items-center gy-2">
                            <Col sm={3}>
                                <p className="text-secondary text-md-end text-center form-label mb-0 pb-0">Adding In(+)</p>
                            </Col>
                            <Col sm={4}>
                                <Form.Group controlId="formAttribute">
                                    <Form.Label className="text-secondary mb-0">Select Column Name 2</Form.Label>
                                    <Form.Select
                                        as="select"
                                        placeholder='Select Column'
                                        value={formData?.budget2?.name2}
                                        onChange={handleAttributeChange2budget2}
                                    >
                                        <option value="">Select Column 2</option>
                                        {attributeOptions?.map(attr => (
                                            <option key={attr?.value} value={attr?.value}>{attr?.label}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col sm={5}>
                                <Form.Group controlId="formValue">
                                    <Form.Label className="text-secondary mb-0">Select Column Value 2</Form.Label>
                                    <Form.Select as="select"
                                        placeholder='Select Column Value 2'
                                        value={formData?.budget2?.value2} onChange={e => setFormData(prev => ({
                                            ...prev, budget2: { ...prev?.budget2, value2: e.target.value }
                                        }))} disabled={!formData?.budget2?.name2}>
                                        <option value="">Select Value 2</option>
                                        {valueOptions2budget2?.map(val => (
                                            <option key={val.value} value={val.value}>{val.label}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Row className="mt-2 justify-content-center align-items-center gy-2">
                                <Col sm={3}>
                                    <p className="text-secondary text-md-end text-center form-label mb-0 pb-0">Multiply By(*)</p>
                                </Col>
                                <Col sm={2}>
                                    <Form.Group controlId="formCount">
                                        <Form.Label className="text-secondary mb-0">Count 2</Form.Label>
                                        <Form.Control type="number" value={formData?.budget2?.count2} onChange={e => setFormData(prev => ({
                                            ...prev, budget2: { ...prev?.budget2, count2: e.target.value }
                                        }))} />
                                    </Form.Group>
                                </Col>
                                <Col sm={5}>
                                    <Form.Group controlId={`selectOption-metric2`}>
                                        <Form.Label className="text-secondary mb-0">Select Option for Event Column2</Form.Label>
                                        <Form.Select
                                            value={formData?.budget2?.select2}
                                            onChange={(e) => setFormData(prev => ({
                                                ...prev,
                                                budget2: {
                                                    ...prev.budget2,
                                                    select2: e.target.value
                                                }
                                            }))}>
                                            <option value="count">Count</option>
                                            <option value="sum">Sum</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col sm={2}>
                                    <Form.Group controlId="formCurrency">
                                        <Form.Label className="text-secondary mb-0">Currency</Form.Label>
                                        <Form.Select value={formData?.budget2?.currency} onChange={e => setFormData(prev => ({
                                            ...prev, budget2: { ...prev?.budget2, currency: e.target.value }
                                        }))}>
                                            <option value="€">Euro</option>
                                            <option value="$">USD</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Row>
                    </Row>
                </div>
            )}
        </div>
    );
}

export default BudgetSelection
