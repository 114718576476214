import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'

const GeoPercentageInput = ({ company, formData, setFormData }) => {
    return (
        <>
            <div className="mt-3">
                <p className="form-label text-secondary pb-2">Geo Percentage</p>
                <div className="px-2">
                    <Row className="border py-2 gy-2">
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formHighgeo">
                                <Form.Label className="text-secondary mb-0">Higher percentage value</Form.Label>
                                <Form.Control type="number" value={formData?.geoPercentage?.high} onChange={e => setFormData(prev => ({
                                    ...prev, geoPercentage: { ...prev?.geoPercentage, high: e.target.value }
                                }))} />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formLowGeo">
                                <Form.Label className="text-secondary mb-0">Lower percentage value</Form.Label>
                                <Form.Control type="number" value={formData?.geoPercentage?.low} onChange={e => setFormData(prev => ({
                                    ...prev, geoPercentage: { ...prev?.geoPercentage, low: e.target.value }
                                }))} />
                            </Form.Group>
                        </Col>
                    </Row>
                </div>
            </div>
            {company?.siteIDShow &&
                <div className="mt-3">
                    <p className="form-label text-secondary pb-2">Category Percentage</p>
                    <div className="px-2">
                        <Row className="border py-2 gy-2">
                            <Col xs={12} md={6}>
                                <Form.Group controlId="formHighcat">
                                    <Form.Label className="text-secondary mb-0">Higher percentage value</Form.Label>
                                    <Form.Control type="number" value={formData?.categoryPercentage?.high} onChange={e => setFormData(prev => ({
                                        ...prev, categoryPercentage: { ...prev?.categoryPercentage, high: e.target.value }
                                    }))} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="formLowcat">
                                    <Form.Label className="text-secondary mb-0">Lower percentage value</Form.Label>
                                    <Form.Control type="number" value={formData?.categoryPercentage?.low} onChange={e => setFormData(prev => ({
                                        ...prev, categoryPercentage: { ...prev?.categoryPercentage, low: e.target.value }
                                    }))} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                </div>
            }
            <div className="mt-3">
                <p className="form-label text-secondary pb-2">Overview PerDay Percentage</p>
                <div className="px-2">
                    <Row className="border py-2 gy-2">
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formHighday">
                                <Form.Label className="text-secondary mb-0">Higher percentage value</Form.Label>
                                <Form.Control type="number" value={formData?.perDayPercentage?.high} onChange={e => setFormData(prev => ({
                                    ...prev, perDayPercentage: { ...prev?.perDayPercentage, high: e.target.value }
                                }))} />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formLowday">
                                <Form.Label className="text-secondary mb-0">Lower percentage value</Form.Label>
                                <Form.Control type="number" value={formData?.perDayPercentage?.low} onChange={e => setFormData(prev => ({
                                    ...prev, perDayPercentage: { ...prev?.perDayPercentage, low: e.target.value }
                                }))} />
                            </Form.Group>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default GeoPercentageInput
