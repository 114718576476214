import React, { useCallback, useMemo } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';

const CountrySelection = ({ formData, setFormData, additional }) => {
    console.log("Form Country", additional?.countryNames)
    const countryOptions = useMemo(() => (
        [{ value: 'all', label: 'Select All' }, ...(additional?.countryNames?.map(country => ({ value: country, label: country })) || [])]
    ), [additional]);

    const handleSelectChange = useCallback((selectedOptions, name) => {
        const isSelectAll = selectedOptions.some(option => option.value === 'all');
        if (isSelectAll) {
            setFormData(prev => ({
                ...prev,
                [name]: countryOptions.slice(1).map(option => option.value)
            }));
        } else {
            setFormData(prev => ({
                ...prev,
                [name]: selectedOptions.map(option => option.value)
            }));
        }
    }, [countryOptions, setFormData]);

    return (
        <Row className="mt-3">
            {additional?.countryNames?.length > 0 ? (
                <Col xs={12}>
                    <Form.Group controlId="formEvent">
                        <Form.Label className="text-secondary">Select Countries to Show in Graphs</Form.Label>
                        <Select
                            isMulti
                            options={countryOptions}
                            value={countryOptions?.filter(option => formData?.countryDistribution?.includes(option?.value))}
                            onChange={selectedOptions => handleSelectChange(selectedOptions, 'countryDistribution')}
                            closeMenuOnSelect={false}
                            aria-label="Country Selection"
                        />
                    </Form.Group>
                </Col>
            ) : (
                <>
                    <Col xs={6}>
                        <Form.Group controlId="countryLabel">
                            <Form.Label className="text-secondary">Country Label</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter country label"
                                value={formData?.countrySelection?.label}
                                onChange={(e) => {
                                    const newLabel = e.target.value;
                                    setFormData((prev) => ({
                                        ...prev,
                                        countrySelection: {
                                            ...prev.countrySelection,
                                            label: newLabel
                                        }
                                    }));
                                }}
                                aria-label="Country Label"
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={6}>
                        <Form.Group controlId="countryValue" className="">
                            <Form.Label className="text-secondary">Country Value</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter country value"
                                value={formData?.countrySelection?.value}
                                onChange={(e) => {
                                    const newValue = e.target.value;
                                    setFormData((prev) => ({
                                        ...prev,
                                        countrySelection: {
                                            ...prev.countrySelection,
                                            value: newValue
                                        }
                                    }));
                                }}
                                aria-label="Country Value"
                            />
                        </Form.Group>
                    </Col>
                </>
            )}
        </Row>
    );
};

export default CountrySelection;
