import { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Bar from '../../components/charts/Bar';
import GeoChart from '../../components/charts/GeoChart';
import ChartHeading from '../../components/shared/ChartHeading';
import CustomCard from '../../components/shared/CustomCard';
import DashboardTable from '../../components/shared/Table';
import TableHeading from '../../components/shared/TableHeading';
import Pagination from '../../components/shared/TablePagination';
import { getChartData, getCityChart, getCompanyMapCountry, getCountryChart, getStateTableData, getStatsData } from '../../services/adminService/adminService';
import MobileBarChart from '../../components/Mobilecomponents/MobileBarChart';
import MobileGeoChart from '../../components/Mobilecomponents/MobileGeoChart';
const Geo = ({ selectedRange, showImage }) => {
    const userData = JSON.parse(localStorage.getItem('user'));
    const cityFranceMap = userData.cityFranceMap;
    const [companyId, setCompanyId] = useState(localStorage.getItem('selectedCompany'));
    const [selectedMap, setSelectedMap] = useState(cityFranceMap);
    const [zoom, setZoom] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [chartLoading, setChartLoading] = useState(true);
    const [pieChartLoading, setPieChartLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(true);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    // const[tableData,setTableDate]
    const [chartData, setChartData] = useState(null);
    const [pieChartData, setPieChartData] = useState(null);
    const [pieChartCountryData, setPieChartCountryData] = useState(null);
    const [pieChartPercentage, setPieChartPercentage] = useState(null);
    const [stats, setStats] = useState(null);
    const [ftdShow, setFtdSow] = useState();
    const [tableData, setTableData] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const GeoHeading = [
        "Rank",
        "STATE",
        tableData?.length > 0 ? Object?.keys(tableData[0])[2] : "FTD’s (unique)",
        tableData?.length > 0 ? `${Object?.keys(tableData[0])[3]}` : "%FTD’s (unique)",
    ];
    const GeoHeading2 = [
        "Rank",
        "STATE",
        tableData?.length > 0 ? `${Object?.keys(tableData[0])[2]}` : "%FTD’s (unique)",
    ];
    const tableHeadings = () => {
        if (ftdShow) return GeoHeading;
        return GeoHeading2;
    };
    // const tableBody = tableData?.map((data) => [
    //     data?.Rank,
    //     data?.State,
    //     data?.FTDsUnique,
    //     `${data?.FTDsPercent}%`,
    // ]) || [];
    const tableBody = tableData?.map((data) => {
        const row = [
            data?.Rank,
            data?.State,
            ...(ftdShow ? [Object.values(data)[2]] : []),
            ...(ftdShow ? [`${Object?.values(data)[3]}%`] : [`${Object?.values(data)[2]}%`]),

            // `${Object.values(data)[2]}%`,
        ];
        return row.filter(value => value != null);
    }) || [];
    const dashboardCards = stats?.dynamicResults
        ? Object.entries(stats.dynamicResults).map(([label, value], index) => ({
            label: label,
            value: index === 0
                ? `${new Intl.NumberFormat('fr-FR').format(value)} ${stats?.currency || ''}`
                : `${new Intl.NumberFormat('fr-FR').format(value)}`,
        }))
        : [];
    const getStats = async (formattedStartDate, formattedEndDate) => {
        try {
            setLoading(true);
            const response = await getStatsData(formattedStartDate, formattedEndDate, companyId);
            setStats(response?.data?.data);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }
    const getChart = async (formattedStartDate, formattedEndDate) => {
        try {
            setChartLoading(true);
            const response = await getChartData(formattedStartDate, formattedEndDate, companyId);
            setChartData(response?.data?.data);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setChartLoading(false);
        } finally {
            setChartLoading(false);
        }
    }
    const getPieChartCountry = async (formattedStartDate, formattedEndDate) => {
        try {
            setPieChartLoading(true);
            const response = await getCountryChart(formattedStartDate, formattedEndDate, companyId);
            setPieChartCountryData(response?.data?.data?.dashboardPie);
            setPieChartPercentage(response?.data?.data?.percDiffer)

        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setPieChartLoading(false);
        } finally {
            setPieChartLoading(false);
        }
    }
    const getPiechartcity = async (formattedStartDate, formattedEndDate, selectedMap) => {
        try {
            setPieChartLoading(true);
            const response = await getCityChart(formattedStartDate, formattedEndDate, companyId, selectedMap);
            setPieChartData(response?.data?.data?.dashboardPie);
            setPieChartPercentage(response?.data?.data?.percDiffer)
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setPieChartLoading(false);
        } finally {
            setPieChartLoading(false);
        }
    }
    const getTable = async (page, formattedStartDate, formattedEndDate) => {
        try {
            setTableLoading(true);
            const response = await getStateTableData(formattedStartDate, formattedEndDate, page, 7, companyId);
            setTableData(response?.data?.data?.data);
            setTotalPages(response?.data?.data?.totalPages);
            setFtdSow(response?.data?.data?.ftdEnabled)
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setTableLoading(false);
        } finally {
            setTableLoading(false);
        }
    }
    const getCompanyMap = async () => {
        try {
            const response = await getCompanyMapCountry(companyId);
            console.log("Response country Map", response?.data?.cityFranceMap);
            setSelectedMap(response?.data?.cityFranceMap)
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    }
    useEffect(() => {
        console.log("Company Id again", companyId)

        const handleCompanyChange = () => {
            setCompanyId(localStorage.getItem('selectedCompany'));
        };
        window.addEventListener('user-update', handleCompanyChange);

        return () => {
            window.removeEventListener('user-update', handleCompanyChange);
        };
    }, [companyId]);
    useEffect(() => {
        if (selectedRange && selectedRange?.length === 2) {
            setCurrentPage(1);
            const [startDate, endDate] = selectedRange;
            const formatLocalDate = (date) => {
                return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
            };
            const formattedStartDate = formatLocalDate(startDate);
            const formattedEndDate = formatLocalDate(endDate);
            setStartDate(formattedStartDate);
            setEndDate(formattedEndDate);
            getCompanyMap()
            getStats(formattedStartDate, formattedEndDate);
            getChart(formattedStartDate, formattedEndDate);
            getPieChartCountry(formattedStartDate, formattedEndDate);
            getPiechartcity(formattedStartDate, formattedEndDate, selectedMap);
        }
    }, [selectedRange, companyId]);
    useEffect(() => {
        if (selectedRange && selectedRange?.length === 2) {
            setCurrentPage(1);
            const [startDate, endDate] = selectedRange;
            const formatLocalDate = (date) => {
                return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
            };
            const formattedStartDate = formatLocalDate(startDate);
            const formattedEndDate = formatLocalDate(endDate);
            getPiechartcity(formattedStartDate, formattedEndDate, selectedMap);
        }
    }, [selectedRange, companyId, selectedMap]);
    useEffect(() => {
        if (selectedRange && selectedRange?.length === 2) {
            const [startDate, endDate] = selectedRange;
            const formatLocalDate = (date) => {
                return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
            };
            const formattedStartDate = formatLocalDate(startDate);
            const formattedEndDate = formatLocalDate(endDate);
            setStartDate(formattedStartDate);
            setEndDate(formattedEndDate);
            getTable(currentPage, formattedStartDate, formattedEndDate);
        }
    }, [selectedRange, currentPage, companyId]);
    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    const [currentData, setCurrentData] = useState([]);
    const [worldwide, setWorldwide] = useState(false);
    useEffect(() => {
        switch (selectedMap) {
            case 'worldwide':
                setCurrentData(pieChartCountryData);
                break;
            case 'FR':
                setCurrentData(pieChartData);
                // getPiechartcity(startDate, endDate, selectedMap);
                break;
            case 'US':
                setCurrentData(pieChartData);
                // getPiechartcity(startDate, endDate, selectedMap);
                break;
            case 'UK':
                setCurrentData(pieChartData);
                // getPiechartcity(startDate, endDate, selectedMap);
                break;
            default:
                setCurrentData(pieChartCountryData);
        }
        // eslint-disable-next-line
    }, [selectedMap, pieChartData, pieChartCountryData]);
    const handleResize = () => {
        if (mapRef.current) {
            mapRef.current.style.width = '100%';
            mapRef.current.style.height = '100%';
        }
    };
    const toggleView = () => {
        setZoom(0.8);
        handleResize();
    };

    const mapRef = useRef();
    return (

        <Container className={`mb-5 ${showImage ? 'px-5' : 'dashboard-page'}`} fluid>
            <CustomCard data={dashboardCards} loading={loading} />
            <Row>
                <Col xl={8}>
                    <div className='bg-white rounded-4 p-4 my-lg-4 mt-4'>
                        <ChartHeading heading={"Performances"} />
                        <div className="d-md-none d-block mb-4"></div>
                        <div className='d-lg-block d-none'>
                            <Bar height={738} data={chartData} loading={chartLoading} />
                        </div>
                        <div className='d-lg-none d-block'>
                            <MobileBarChart data={chartData} loading={chartLoading} />
                        </div>
                    </div>
                </Col>
                <Col xl={4}>
                    <div className='bg-white rounded-4 p-4 my-4'>
                        <ChartHeading heading={"Performances"} />
                        <div className='my-3 mb-5'>
                            <div className='d-lg-block d-none'>
                                <GeoChart mapRef={mapRef} handleResize={handleResize}
                                    currentData={currentData} worldwide={worldwide} toggleView={toggleView}
                                    loading={pieChartLoading}
                                    zoom={zoom}
                                    selectedMap={selectedMap}
                                    setSelectedMap={setSelectedMap}
                                    setZoom={setZoom}
                                    cityFranceMap={cityFranceMap}
                                    pieChartPercentage={pieChartPercentage}
                                />
                            </div>
                            <div className='d-lg-none d-block'>
                                <MobileGeoChart mapRef={mapRef} handleResize={handleResize}
                                    currentData={currentData} worldwide={worldwide} toggleView={toggleView}
                                    loading={pieChartLoading}
                                    zoom={zoom}
                                    selectedMap={selectedMap}
                                    cityFranceMap={cityFranceMap}
                                    setSelectedMap={setSelectedMap}
                                    setZoom={setZoom}
                                    pieChartPercentage={pieChartPercentage}
                                />
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className={`${showImage ? 'pb-5' : ''}`}>
                    <div className={`p-4 bg-white rounded-4 m-1 ${showImage ? '' : ''}`}>
                        <div className='d-md-flex justify-content-between  align-items-center mb-md-5'>
                            <TableHeading heading={"By Campaign"} />
                            <div className='d-lg-block d-none'>
                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onNext={handleNext}
                                    onPrevious={handlePrevious}
                                    loading={tableLoading}
                                />
                            </div>
                        </div>
                        <DashboardTable
                            tableBody={tableBody}
                            tableHeadings={tableHeadings()}
                            loading={tableLoading}
                        />
                        <div className='d-lg-none d-block'>
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onNext={handleNext}
                                onPrevious={handlePrevious}
                                loading={tableLoading}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </Container >

    );
};

export default Geo;
