import React from 'react';
import { Card, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaPhone } from 'react-icons/fa6';
import dropdown from "../../assets/svg/dropdown.svg";
import Toggle from 'react-toggle';
import disabledframe from "../../assets/svg/disabled.svg";
import placeholder from "../../assets/schoolimage.png";
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaTrash } from 'react-icons/fa';
import moment from 'moment';

const UserCard = ({ loadingButton, id, Name, image, status, handleToggleChange, handleDelete, Phone, CreatedAt, designation }) => {
    const formattedCreatedAt = moment(CreatedAt).format('MMM D, YYYY');
    const navigate = useNavigate()

    const handlePhoneClick = () => {
        if (Phone) {
            window.location.href = `tel:${Phone}`;
            window.open(`tel:${Phone}`, "_blank");
        }
    };
    const handleView = () => {
        navigate(`/dashboard/view-company/${id}`);
    }
    return (
        <>
            <Card
                className='text-center my-3 py-2 border-0 school-cards text-decoration-none'
                style={{
                    borderRadius: "20px",
                    overflow: "hidden",
                    backgroundColor: !status ? "#fbe5e5" : "#ffffff",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
            >
                <div className='text-end me-2'>
                    <Dropdown>
                        <Dropdown.Toggle className='more bg-transparent text-black border-0 e-caret-hide rounded-0' id="dropdown-basic" disabled={loadingButton}>
                            <Card.Img style={{ width: "24px", height: "24px" }} variant="top" src={dropdown} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className='border-0' style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}>
                            {/* <Dropdown.Item as={Link} to={`edit-company/${id}`}>
                                <FaEdit className="me-2" />
                                Edit
                            </Dropdown.Item> */}
                            <Dropdown.Item onClick={handleDelete} style={{ color: "red" }}>
                                <FaTrash className="me-2" />
                                Delete
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className='d-flex justify-content-center align-items-center' style={{
                    position: 'relative', width: "100px", height: "100px", margin: "auto", borderRadius: "60px", background: '#09332C', overflow: "hidden", border: status ? "5px solid #FFE521" : "none",
                    boxShadow: "rgba(225, 229, 33, 0.4) 0px 7px 29px 0px"
                }}>
                    <Card.Img onClick={handleView} className='w-100 pointer' variant="top" src={image ? image : placeholder} />
                    {!status && (
                        <>
                            <div onClick={handleView} style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'black',
                                opacity: 0.5,
                                zIndex: 1
                            }} />
                            <div onClick={handleView} style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundImage: `url(${disabledframe})`,
                                backgroundSize: 'cover',
                                zIndex: 2,
                                cursor: 'pointer'
                            }} />
                        </>
                    )}
                </div>

                <Card.Body>
                    <Card.Title onClick={handleView}
                        style={{
                            fontSize: "18px",
                            fontWeight: 700,
                            lineHeight: "27px",
                            color: "#303972",
                            cursor: 'pointer'
                        }}
                    >
                        {Name}
                    </Card.Title>
                    {CreatedAt && (
                        <Card.Text className='text-muted'
                            style={{
                                fontSize: "15px",
                                fontWeight: 400,
                                lineHeight: "22.5px",
                                color: "#A098AE"
                            }}
                        >
                            {formattedCreatedAt}
                        </Card.Text>

                    )}
                    <label>
                        <Toggle
                            icons={{
                                checked: <span style={{ fontSize: "8px" }}> ON</span>,
                                unchecked: <span style={{ fontSize: "8px" }}>OFF</span>,
                            }}
                            checked={status ? true : false}
                            onChange={handleToggleChange}
                            disabled={loadingButton}
                        />
                    </label>


                    <div className="d-flex gap-4 justify-content-center mt-1">
                        {Phone && (

                            <>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id={`tooltip-top`}>{Phone}</Tooltip>}
                                >

                                    <button className='d-flex justify-content-center align-items-center border-0' style={{ background: "#303972", borderRadius: "60px", height: "30px", width: "30px" }} onClick={handlePhoneClick}>
                                        <FaPhone style={{ color: "white" }} />
                                    </button>

                                </OverlayTrigger>
                            </>
                        )}
                    </div>
                </Card.Body>
            </Card>
        </>
    );
};

export default UserCard;

