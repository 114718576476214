import { GetApiData, PostFormData } from "../http-client";

export const addCompany = function (data) {
  return GetApiData(`/company/create`, "POST", data, true);
};
export const addLocation = function (data, companyId) {
  return GetApiData(`/company/create/location/${companyId}`, "POST", data, true);
};
export const updateLocation = function (companyId, locationIndex, updatedLocation) {
  return GetApiData(`/company/update/location/${companyId}/${locationIndex}`, "PUT", { updatedLocation }, true);
};
export const deleteLocation = function (companyId, locationIndex) {
  return GetApiData(`/company/location/${companyId}/${locationIndex}`, "DELETE", null, true);
};
export const UpdateCompany = function (data) {
  return GetApiData(`/company`, "PUT", data, true);
};
export const AditionalInfo = function (data) {
  return GetApiData(`/company/aditional`, "PUT", data, true);
};
export const adduser = function (data) {
  return GetApiData(`/user/create`, "POST", data, true);
};
export const addUsersToCompany = function (companyId, userIds) {
  return GetApiData(`/company/createUser/${companyId}`, "POST", { userIds }, true);
};
export const DeleteUser = function (userId, companyId) {
  return GetApiData(`/user/delete/${userId}/${companyId}`, "DELETE", null, true);
};
export const GetWaitingUser = function () {
  return GetApiData(`/waiting-user/`, "GET", null, true);
};
export const GetCompanyUser = function (companyId) {
  return GetApiData(`/user/all/${companyId}`, "GET", null, true);
};
export const GetCompany = function () {
  return GetApiData(`/company/`, "GET", null, true);
};
export const GetSingleCompany = async (companyId) => {
  return await GetApiData(`/company/${companyId}`, "GET", null, true);
};
export const GetCompanyCampagin = async (companyId) => {
  return await GetApiData(`/company/campagin/${companyId}`, "GET", null, true);
};
export const GetUsers = async () => {
  return await GetApiData(`/user/allUser`, "GET", null, true);
};
export const GetAttributeValues = async (companyId, attributeName) => {
  return await GetApiData(`/company/attr/${companyId}/${attributeName}`, "GET", null, true);
};
export const UpdateCompanyStatus = async (companyId, newStatus) => {
  return await GetApiData(
    `/company/status/${companyId}`,
    "PUT",
    { newStatus },
    true
  );
};
export const UpdateCompanyDetail = async (companyId, data) => {
  return await GetApiData(`/company/update/${companyId}`, "PUT", data, true);
};
export const deleteCompany = async (companyId) => {
  return await GetApiData(`/company/${companyId}`, "DELETE", null, true);
};
export const profileUser = function (data) {
  return PostFormData(`/upload/`, 'POST', data, true);
}
export const uploadmultiple = function (data) {
  return PostFormData(`/upload/multiple`, 'POST', data, true);
}