import { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { MdDeleteForever } from "react-icons/md";
import Skeleton from 'react-loading-skeleton';
import { DeleteUser } from '../../utils/admin/admin.service';
import Swal from 'sweetalert2';

const UsersTable = ({ tableBody, companyId, loading, reCall }) => {
    const skeletonRows = Array(10).fill(null);
    const [user, setUser] = useState(tableBody);

    const handleConfirmDelete = async (userId) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete this user.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#1cc76d",
            cancelButtonColor: "#6C757D",
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel",
            allowOutsideClick: false,
            preConfirm: () => {
                Swal.showLoading();
                return new Promise(async (resolve, reject) => {
                    try {
                        await DeleteUser(userId, companyId);
                        resolve();
                    } catch (error) {
                        reject(error?.response?.data?.error || 'Network Error');
                    }
                });
            }
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Updated!",
                    text: "The user has been deleted successfully from the company.",
                    icon: "success",
                    confirmButtonColor: "#28a745",
                });
                reCall();
            }
        }).catch((error) => {
            Swal.fire({
                title: "Error!",
                text: error || 'An error occurred while deleting the user from the company.',
                icon: "error",
                confirmButtonColor: "#dc3545",
            });
        });
    };

    useEffect(() => {
        setUser(tableBody);
    }, [tableBody]);

    const tableHeadings = ["First Name", "Last Name", "User Email", "Phone", "Country", "Action"];

    return (
        <>
            <div className="shadow p-3 rounded-3 mb-4">
                <Table hover responsive>
                    <thead>
                        <tr>
                            {tableHeadings?.map((th, idx) => (
                                <th className='t-head py-3' style={{ backgroundColor: "#F7FAFC", color: "#A0AEC0" }} key={idx}>{th}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            skeletonRows.map((_, i) => (
                                <tr key={i}>
                                    {tableHeadings.map((_, idx) => (
                                        <td key={idx}><Skeleton height={20} /></td>
                                    ))}
                                </tr>
                            ))
                        ) : user && user?.length > 0 ? (
                            user.map((row, i) => (
                                <tr key={i}>
                                    <td className='py-3'>{row?.firstname}</td>
                                    <td className='py-3'>{row?.lastname}</td>
                                    <td className='py-3'>{row?.email}</td>
                                    <td className='py-3'>{row?.phone ? `+${row.phone}` : ''}</td>
                                    <td className='py-3'>{row?.country}</td>
                                    <td className='py-3'>
                                        <Button onClick={() => handleConfirmDelete(row?._id)} className='text-danger bg-transparent border-0 p-0'>
                                            <MdDeleteForever />
                                        </Button>
                                    </td>

                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={tableHeadings?.length} className="text-center py-3">
                                    No users available.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </>
    );
};

export default UsersTable;
