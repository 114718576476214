export const dashboardCards = [
  {
    label: "Budget",
    value: `${80}`,
  },
  {
    label: "Installs",
    value: "80",
  },
  {
    label: "Signed (unique)",
    value: "8",
  },
  {
    label: "FTD’s (unique)FTD’s (unique)",
    value: "20",
  },
];
export const dashboardPie = [
  { name: "Week 35", value: 43 },
  { name: "Week 36", value: 43 },
  { name: "Week 33", value: 43 },
  { name: "Week 34", value: 43 },
];

export const DashboardtableBody = [
  {
    ID: "101",
    "IOS UNIBET FR": "IAB 2024",
    D: "66 99 69610",
    Week: "Week 34",
    USA: "2",
  },
  {
    ID: "102",
    "IOS UNIBET FR": "IAB 2024",
    D: "66 99 69610",
    Week: "Week 34",
    USA: "2",
  },
  {
    ID: "103",
    "IOS UNIBET FR": "IAB 2024",
    D: "66 99 69610",
    Week: "Week 34",
    USA: "2",
  },
  {
    ID: "104",
    "IOS UNIBET FR": "IAB 2024",
    D: "66 99 69610",
    Week: "Week 34",
    USA: "2",
  },
  {
    ID: "105",
    "IOS UNIBET FR": "IAB 2024",
    D: "66 99 69610",
    Week: "Week 34",
    USA: "2",
  },
  {
    ID: "106",
    "IOS UNIBET FR": "IAB 2024",
    D: "66 99 69610",
    Week: "Week 34",
    USA: "2",
  },
  {
    ID: "107",
    "IOS UNIBET FR": "IAB 2024",
    D: "66 99 69610",
    Week: "Week 34",
    USA: "2",
  },
  {
    ID: "108",
    "IOS UNIBET FR": "IAB 2024",
    D: "66 99 69610",
    Week: "Week 34",
    USA: "2",
  },
  {
    ID: "109",
    "IOS UNIBET FR": "IAB 2024",
    D: "66 99 69610",
    Week: "Week 34",
    USA: "2",
  },
  {
    ID: "110",
    "IOS UNIBET FR": "IAB 2024",
    D: "66 99 69610",
    Week: "Week 34",
    USA: "2",
  },
];
export const DashboardtableHeadings = [
  "ID",
  "IOS UNIBET FR",
  "D",
  "Week",
  "USA",
];

export const DashboardColors = [
  "var(--color1)",
  "var(--color2)",
  "var(--color2)",
  "var(--color2)",
];
export const DashboardChartData = [
  {
    name: "2 Sept 2023",
    al_complete_registration: 590,
    al_first_depot: 800,
  },
  {
    name: "3 Sept 2023",
    al_complete_registration: 868,
    al_first_depot: 967,
  },
  {
    name: "4 Sept 2023",
    al_complete_registration: 1397,
    al_first_depot: 1098,
  },
  {
    name: "5 Sept 2023",
    al_complete_registration: 1480,
    al_first_depot: 1200,
  },
  {
    name: "6 Sept 2023",
    al_complete_registration: 1520,
    al_first_depot: 1108,
  },
  {
    name: "7 Sept 2023",
    al_complete_registration: 1400,
    al_first_depot: 680,
  },
];

export const MetricsCardsData = [
  {
    label: "%CTR",
    value: "2,8%",
  },
  {
    label: "FTS’s (unique)",
    value: "7",
  },
  {
    label: "Re-deposit’s",
    value: "1908",
  },
];
export const MetricsBarChartData = [
  { name: "MON", value: 25 },
  { name: "TUE", value: 25 },
  { name: "WED", value: 25 },
  { name: "THU", value: 75 },
  { name: "FRI", value: 25 },
  { name: "SAT", value: 25 },
  { name: "SUN", value: 25 },
];

export const MetricsTableHeadings = [
  "Rank",
  "Customer",
  "App ID",
  "WEEK",
  "Country",
];

export const MetricsTableBody = [
  {
    Rank: "1",
    Customer: "Unibet",
    "App ID": "IAB 2024",
    WEEK: "Week 34",
    Country: "USA",
  },
  {
    Rank: "2",
    Customer: "Bet365",
    "App ID": "IAB 2023",
    WEEK: "Week 33",
    Country: "UK",
  },
  {
    Rank: "3",
    Customer: "DraftKings",
    "App ID": "IAB 2022",
    WEEK: "Week 34",
    Country: "Canada",
  },
  {
    Rank: "4",
    Customer: "FanDuel",
    "App ID": "IAB 2025",
    WEEK: "Week 34",
    Country: "Australia",
  },
  {
    Rank: "5",
    Customer: "Caesars",
    "App ID": "IAB 2021",
    WEEK: "Week 33",
    Country: "Germany",
  },
  {
    Rank: "6",
    Customer: "MGM",
    "App ID": "IAB 2020",
    WEEK: "Week 32",
    Country: "USA",
  },
];

export const CreativesCards = [
  {
    label: "Budget",
    value: `${80}`,
  },
  {
    label: "Installs",
    value: "80",
  },
  {
    label: "Signed (unique)",
    value: "8",
  },
  {
    label: "FTD’s (unique)FTD’s (unique)",
    value: "20",
  },
];

export const CreativestableHeadings = [
  "Rank",
  "CREATIVE NAME",
  "FTD’s (unique)",
  "%FTD’s (UNIQUE)",
  "BUDGET",
];
export const CreativeTableBody = [
  {
    Rank: 1,
    "CREATIVE NAME": "Creative 1",
    "FTD’s (unique)": 50,
    "%FTD’s (UNIQUE)": "25%",
    BUDGET: "$1,000",
  },
  {
    Rank: 2,
    "CREATIVE NAME": "Creative 2",
    "FTD’s (unique)": 30,
    "%FTD’s (UNIQUE)": "15%",
    BUDGET: "$800",
  },
  {
    Rank: 3,
    "CREATIVE NAME": "Creative 3",
    "FTD’s (unique)": 40,
    "%FTD’s (UNIQUE)": "20%",
    BUDGET: "$900",
  },
  {
    Rank: 4,
    "CREATIVE NAME": "Creative 4",
    "FTD’s (unique)": 60,
    "%FTD’s (UNIQUE)": "30%",
    BUDGET: "$1,200",
  },
  {
    Rank: 5,
    "CREATIVE NAME": "Creative 5",
    "FTD’s (unique)": 35,
    "%FTD’s (UNIQUE)": "18%",
    BUDGET: "$850",
  },
  {
    Rank: 6,
    "CREATIVE NAME": "Creative 6",
    "FTD’s (unique)": 45,
    "%FTD’s (UNIQUE)": "22%",
    BUDGET: "$950",
  },
];

export const SiteCards = [
  {
    label: "Budget",
    value: `${80}`,
  },
  {
    label: "Installs",
    value: "80",
  },
  {
    label: "Signed (unique)",
    value: "8",
  },
  {
    label: "FTD’s (unique)FTD’s (unique)",
    value: "20",
  },
];

export const SiteHeading = [
  "Rank",
  "APP ID",
  "CATEGORY",
  "FTD’s (unique)",
  "%FTD’s (UNIQUE)",
];

export const SitetableBody = [
  {
    Rank: "1",
    "APP ID": "APP-001",
    CATEGORY: "Cat Memes",
    "FTD’s (unique)": "150",
    "%FTD’s (UNIQUE)": "60%",
  },
  {
    Rank: "2",
    "APP ID": "APP-002",
    CATEGORY: "Dog Fails",
    "FTD’s (unique)": "200",
    "%FTD’s (UNIQUE)": "75%",
  },
  {
    Rank: "3",
    "APP ID": "APP-003",
    CATEGORY: "Food Fights",
    "FTD’s (unique)": "120",
    "%FTD’s (UNIQUE)": "50%",
  },
  {
    Rank: "4",
    "APP ID": "APP-004",
    CATEGORY: "Unicorn Selfies",
    "FTD’s (unique)": "180",
    "%FTD’s (UNIQUE)": "65%",
  },
  {
    Rank: "5",
    "APP ID": "APP-005",
    CATEGORY: "Epic Fails",
    "FTD’s (unique)": "130",
    "%FTD’s (UNIQUE)": "55%",
  },
  {
    Rank: "6",
    "APP ID": "APP-006",
    CATEGORY: "Prank Wars",
    "FTD’s (unique)": "175",
    "%FTD’s (UNIQUE)": "70%",
  },
  {
    Rank: "7",
    "APP ID": "APP-007",
    CATEGORY: "Dancing Babies",
    "FTD’s (unique)": "160",
    "%FTD’s (UNIQUE)": "66%",
  },
  {
    Rank: "8",
    "APP ID": "APP-008",
    CATEGORY: "Pets vs Mirrors",
    "FTD’s (unique)": "140",
    "%FTD’s (UNIQUE)": "58%",
  },
  {
    Rank: "9",
    "APP ID": "APP-009",
    CATEGORY: "Squirrel Olympics",
    "FTD’s (unique)": "110",
    "%FTD’s (UNIQUE)": "48%",
  },
  {
    Rank: "10",
    "APP ID": "APP-010",
    CATEGORY: "Water Balloon Fights",
    "FTD’s (unique)": "190",
    "%FTD’s (UNIQUE)": "72%",
  },
];
export const SiteBarCHartData = [
  {
    name: "2 Sept 2023",
    al_complete_registration: 590,
    al_first_depot: 800,
  },
  {
    name: "3 Sept 2023",
    al_complete_registration: 868,
    al_first_depot: 967,
  },
  {
    name: "4 Sept 2023",
    al_complete_registration: 1397,
    al_first_depot: 1098,
  },
  {
    name: "5 Sept 2023",
    al_complete_registration: 200,
    al_first_depot: 0,
  },
  {
    name: "6 Sept 2023",
    al_complete_registration: 800,
    al_first_depot: 0,
  },
  {
    name: "7 Sept 2023",
    al_complete_registration: 500,
    al_first_depot: 0,
  },
];

export const GeoHeading = [
  "Rank",
  "STATE",
  "FTD’s (unique)",
  "%FTD’s (UNIQUE)",
];

export const GeoTableBody = [
  {
    Rank: 1,
    STATE: "California",
    "FTD’s (unique)": 120,
    "%FTD’s (UNIQUE)": "30%",
  },
  {
    Rank: 2,
    STATE: "Texas",
    "FTD’s (unique)": 95,
    "%FTD’s (UNIQUE)": "25%",
  },
  {
    Rank: 3,
    STATE: "Florida",
    "FTD’s (unique)": 75,
    "%FTD’s (UNIQUE)": "20%",
  },
  {
    Rank: 4,
    STATE: "New York",
    "FTD’s (unique)": 60,
    "%FTD’s (UNIQUE)": "15%",
  },
  {
    Rank: 5,
    STATE: "Illinois",
    "FTD’s (unique)": 50,
    "%FTD’s (UNIQUE)": "10%",
  },
  {
    Rank: 6,
    STATE: "Pennsylvania",
    "FTD’s (unique)": 45,
    "%FTD’s (UNIQUE)": "10%",
  },
  {
    Rank: 7,
    STATE: "Ohio",
    "FTD’s (unique)": 40,
    "%FTD’s (UNIQUE)": "5%",
  },
  {
    Rank: 8,
    STATE: "Georgia",
    "FTD’s (unique)": 35,
    "%FTD’s (UNIQUE)": "5%",
  },
];

export const CityData = [
  { name: "Clermont-Ferrand", value: 100, region: "Île-de-France" },
  { name: "Lagnieu", value: 100, region: "Provence-Alpes-Côte d'Azur" },
  { name: "Lyon", value: 100, region: "Auvergne-Rhône-Alpes" },
  { name: "Toulouse", value: 25, region: "Occitanie" },
];

export const CountryData = [
  { name: "US", value: 100, region: "North America" },
  { name: "FR", value: 75, region: "Europe" },
  { name: "PK", value: 50, region: "Europe" },
  { name: "CH", value: 25, region: "Asia" },
];
export const stateMarkers = [
  { name: "Alabama", coordinates: [-86.9023, 32.3182] },
  { name: "Alaska", coordinates: [-154.493062, 63.588753] },
  { name: "Arizona", coordinates: [-111.0937, 34.0489] },
  { name: "Arkansas", coordinates: [-92.3731, 34.9697] },
  { name: "California", coordinates: [-119.4179, 36.7783] },
  { name: "Colorado", coordinates: [-105.7821, 39.5501] },
  { name: "Connecticut", coordinates: [-72.6851, 41.6032] },
  { name: "Delaware", coordinates: [-75.5277, 38.9108] },
  { name: "Florida", coordinates: [-81.5158, 27.9944] },
  { name: "Georgia", coordinates: [-83.4412, 32.1656] },
  { name: "Hawaii", coordinates: [-155.5828, 19.8968] },
  { name: "Idaho", coordinates: [-114.742, 44.0682] },
  { name: "Illinois", coordinates: [-89.3985, 40.6331] },
  { name: "Indiana", coordinates: [-86.1349, 40.2672] },
  { name: "Iowa", coordinates: [-93.0977, 41.878] },
  { name: "Kansas", coordinates: [-98.4842, 39.0119] },
  { name: "Kentucky", coordinates: [-84.270, 37.8393] },
  { name: "Louisiana", coordinates: [-91.9623, 30.9843] },
  { name: "Maine", coordinates: [-69.4455, 45.2538] },
  { name: "Maryland", coordinates: [-76.6413, 39.0458] },
  { name: "Massachusetts", coordinates: [-71.3824, 42.4072] },
  { name: "Michigan", coordinates: [-85.6024, 44.3148] },
  { name: "Minnesota", coordinates: [-94.6859, 46.7296] },
  { name: "Mississippi", coordinates: [-89.3985, 32.3547] },
  { name: "Missouri", coordinates: [-91.8318, 37.9643] },
  { name: "Montana", coordinates: [-110.3626, 46.8797] },
  { name: "Nebraska", coordinates: [-99.9018, 41.4925] },
  { name: "Nevada", coordinates: [-116.4194, 38.8026] },
  { name: "New Hampshire", coordinates: [-71.5724, 43.1939] },
  { name: "New Jersey", coordinates: [-74.4057, 40.0583] },
  { name: "New Mexico", coordinates: [-105.8701, 34.5199] },
  { name: "New York", coordinates: [-74.2179, 43.2994] },
  { name: "North Carolina", coordinates: [-79.0193, 35.7596] },
  { name: "North Dakota", coordinates: [-101.0020, 47.5515] },
  { name: "Ohio", coordinates: [-82.9071, 40.4173] },
  { name: "Oklahoma", coordinates: [-97.5164, 35.0078] },
  { name: "Oregon", coordinates: [-120.5542, 43.8041] },
  { name: "Pennsylvania", coordinates: [-77.1945, 41.2033] },
  { name: "Rhode Island", coordinates: [-71.4774, 41.5801] },
  { name: "South Carolina", coordinates: [-81.1637, 33.8361] },
  { name: "South Dakota", coordinates: [-99.9018, 43.9695] },
  { name: "Tennessee", coordinates: [-86.5804, 35.5175] },
  { name: "Texas", coordinates: [-99.9018, 31.9686] },
  { name: "Utah", coordinates: [-111.0937, 39.3210] },
  { name: "Vermont", coordinates: [-72.5778, 44.5588] },
  { name: "Virginia", coordinates: [-78.6569, 37.4316] },
  { name: "Washington", coordinates: [-120.7401, 47.7511] },
  { name: "West Virginia", coordinates: [-80.4549, 38.5976] },
  { name: "Wisconsin", coordinates: [-89.6165, 43.7844] },
  { name: "Wyoming", coordinates: [-107.2903, 43.0759] },
];

export const stateMarkersFrance = [
  { name: "Île-de-France", coordinates: [2.3522, 48.8566] },         // Paris
  { name: "Nouvelle-Aquitaine", coordinates: [-0.5792, 44.8378] },   // Bordeaux
  { name: "Auvergne-Rhône-Alpes", coordinates: [4.8357, 45.7640] },  // Lyon
  { name: "Centre-Val de Loire", coordinates: [1.7000, 47.7516] },   // Tours
  { name: "Provence-Alpes-Côte d'Azur", coordinates: [5.3698, 43.2965] },  // Marseille
  { name: "Grand Est", coordinates: [7.7521, 48.5734] },             // Strasbourg
  { name: "Hauts-de-France", coordinates: [3.0573, 50.6292] },       // Lille
  { name: "Occitanie", coordinates: [3.8767, 43.6108] },             // Montpellier
  { name: "Bourgogne-Franche-Comté", coordinates: [5.0415, 47.3220] },// Dijon
  { name: "Normandie", coordinates: [0.5792, 49.4432] },             // Rouen
  { name: "Pays de la Loire", coordinates: [-1.5536, 47.2184] },     // Nantes
  { name: "Bretagne", coordinates: [-2.9456, 48.2020] },             // Saint-Brieuc
  { name: "Corse", coordinates: [9.1217, 42.0396] },                 // Corte (Corsica)
  { name: "Guadeloupe", coordinates: [-61.5510, 16.2650] },          // Basse-Terre
  { name: "Guyane", coordinates: [-52.3350, 4.9224] },               // Cayenne
  { name: "La Réunion", coordinates: [55.5364, -21.1151] },          // Saint-Denis
  { name: "Martinique", coordinates: [-61.0242, 14.6415] },          // Fort-de-France
  { name: "Mayotte", coordinates: [45.2043, -12.8275] },             // Mamoudzou
];

