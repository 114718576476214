import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthVerify } from "./auth.utils";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [isVerified, setIsVerified] = useState(false);
  const navigate = useNavigate();

  const verifyAuth = () => {
    const verify = AuthVerify();
    setIsVerified(verify);
  };

  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("selectedCompany");
    setIsVerified(false);
    navigate("/login");
  };

  useEffect(() => {
    verifyAuth();
  }, []);

  return (
    <AuthContext.Provider value={{ isVerified, verifyAuth, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
