import React, { useCallback, useMemo } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select';

const CategorySelection = ({ formData, company, setFormData, additional }) => {
    const categoryOptions = useMemo(() => (
        [{ value: 'all', label: 'Select All' }, ...(additional?.categoryNames?.map(category => ({ value: category, label: category })) || [])]
    ), [additional]);
    const handleSelectChange = useCallback((selectedOptions, name) => {
        if (selectedOptions.some(option => option.value === 'all')) {
            setFormData(prev => ({
                ...prev,
                [name]: categoryOptions
                    .filter(option => option.value !== 'all')
                    .map(option => option.value)
            }));
        } else {
            setFormData(prev => ({
                ...prev,
                [name]: selectedOptions.filter(option => option.value !== 'all').map(option => option.value)
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryOptions]);

    return (
        <div>
            {company?.siteIDShow &&
                <Row className="mt-3">
                    <Col xs={12}>
                        <Form.Group controlId="formCategory">
                            <Form.Label className="text-secondary">Select Categories to Show in Site Id's</Form.Label>
                            <Select
                                isMulti
                                options={categoryOptions}
                                value={categoryOptions.filter(option =>
                                    formData?.categoryShow?.includes(option?.value) && option.value !== 'all'
                                )}
                                onChange={selectedOptions => handleSelectChange(selectedOptions, 'categoryShow')}
                                closeMenuOnSelect={false}
                            />

                        </Form.Group>
                    </Col>
                </Row>

            }
        </div>
    )
}

export default CategorySelection
