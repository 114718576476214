import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'

const DayDistributionSelection = ({ formData, setFormData, attributeOptions, handleAttributeChangeDay, valueOptionsDay }) => {
    return (
        <div className="mt-3">
            <p className="form-label text-secondary pb-2">Select Day Distribution Event Show in Metrics Graph</p>
            <div className="px-2">
                <Row className="border py-2 gy-2">
                    <Col sm={6}>
                        <Form.Group controlId="formAttribute">
                            <Form.Label className="text-secondary mb-0">Select Column name</Form.Label>
                            <Form.Select value={formData?.dayDistribution?.name} onChange={handleAttributeChangeDay}>
                                <option value="">Select Column</option>
                                {attributeOptions?.map(attr => (
                                    <option key={attr.value} value={attr.value}>{attr.label}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group controlId="formValue">
                            <Form.Label className="text-secondary mb-0">Select Column value</Form.Label>
                            <Form.Select value={formData?.dayDistribution?.value} onChange={e => setFormData(prev => ({
                                ...prev, dayDistribution: { ...prev?.dayDistribution, value: e.target.value }
                            }))} disabled={!formData?.dayDistribution?.name}>
                                <option value="">Select Value</option>
                                {valueOptionsDay?.map(val => (
                                    <option key={val.value} value={val.value}>{val.label}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group controlId={`selectOption-metric`}>
                            <Form.Label className="text-secondary mb-0"> Select Option</Form.Label>
                            <Form.Select
                                value={formData?.dayDistribution?.select}
                                onChange={(e) => setFormData(prev => ({
                                    ...prev,
                                    dayDistribution: {
                                        ...prev.dayDistribution,
                                        select: e.target.value
                                    }
                                }))}>
                                <option value="count">Count</option>
                                <option value="sum">Sum</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default DayDistributionSelection
