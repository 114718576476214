import { useState, useRef } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { uploadmultiple } from "../../utils/admin/admin.service";
import Swal from "sweetalert2";

const UploadIconModal = ({ show, onHide }) => {
    const [loading, setLoading] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [error, setError] = useState('');
    let fileInputRefMultiple = useRef(null);

    const handleImageUploadMultiple = async (event) => {
        setLoading(true);
        const files = Array.from(event.target.files);
        const oversizedFiles = files.filter(file => file.size > 2 * 1024 * 1024); // 2 MB in bytes

        if (files.length > 50) {
            Swal.fire({
                icon: 'error',
                title: 'Oops!',
                text: 'You can only upload up to 10 files.',
                confirmButtonText: 'Ok',
            });
            setLoading(false);
            fileInputRefMultiple.current.value = '';
            return;
        }
        if (oversizedFiles.length > 0) {
            Swal.fire({
                icon: 'error',
                title: 'File size exceeds limit!',
                text: 'Each file must be smaller than 2 MB.',
                confirmButtonText: 'Ok',
            });
            setLoading(false);
            fileInputRefMultiple.current.value = '';
            return;
        }
        Swal.fire({
            title: 'Uploading...',
            text: 'Please wait while we upload your files.',
            allowOutsideClick: false,  // Prevent closing the modal during upload
            didOpen: () => {
                Swal.showLoading(); // Show loading spinner
            }
        });

        const formData = new FormData();

        files.forEach(file => {
            formData.append('attachments', file);
        });

        try {
            const response = await uploadmultiple(formData); // Call your API to upload the images
            setSelectedFiles(prevFiles => [...prevFiles, ...files]);
            console.log('Upload success:', response);
            console.log('Selected Files:', selectedFiles);
            Swal.close();
        } catch (error) {
            console.error('Error during image upload:', error);
            Swal.fire({
                icon: 'error',
                title: 'Upload Failed',
                text: 'There was an error while uploading the files.',
            });
        } finally {
            fileInputRefMultiple.current.value = '';
            setLoading(false);
        }
    };

    return (
        <Modal show={show} onHide={onHide} centered size="lg" className='py-0 my-0' keyboard={false} backdrop="static">
            <Modal.Header className='border-0 mb-0 pb-0' closeButton />
            <Modal.Body>
                <h3 className='text-center text-box pb-2' style={{ color: "var(--primary-color)" }}>Upload Company APP ICONS</h3>
                <Row className="mt-3">
                    <Col xs={12} lg={12}>
                        <Form.Group controlId="formCompanyLogo">
                            <Form.Label className="text-secondary">Company Icons</Form.Label>
                            <div className="d-flex flex-column align-items-start gap-2">
                                <Button
                                    variant="secondary"
                                    className="w-100 py-2"
                                    style={{ background: "transparent", color: 'var(--color4)', }}
                                    disabled={loading}
                                    onClick={() => fileInputRefMultiple.current.click()}
                                >
                                    {"Upload Icons"}
                                    <br />
                                    <small className="text-muted" style={{ fontSize: "12px" }}>
                                        Max 50 files, Max 2 MB per file
                                    </small>
                                </Button>
                                <div className="d-flex flex-column align-items-start mt-2 w-100">
                                    <input
                                        type="file"
                                        ref={fileInputRefMultiple}
                                        style={{ display: 'none' }}
                                        accept=".png, .jpg, .jpeg"
                                        onChange={handleImageUploadMultiple}
                                        multiple // Allow multiple files to be selected
                                    />
                                    {selectedFiles?.length > 0 &&
                                        <>
                                            <div className="px-1 w-100">
                                                <strong>Uploaded Icons:</strong>
                                                <ul className="list-group w-100 pt-2">
                                                    {selectedFiles?.map((file, index) => (
                                                        <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                                            {file.name}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            {/* <div className="mt-2">
                                                <span className="text-danger">You can upload up to 10 files only.</span>
                                            </div> */}
                                        </>
                                    }
                                </div>
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
                <div className="d-flex justify-content-end pt-2 mt-3">
                    <Button onClick={onHide} className='bg-secondary border-0 px-4 py-2' disabled={loading}>Cancel</Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default UploadIconModal;
