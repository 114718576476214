import { useState, useRef } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { profileUser, UpdateCompany } from "../../utils/admin/admin.service";
import { CountrySelect } from "react-country-state-city";
import PhoneInput from "react-phone-input-2";
import Swal from "sweetalert2";

const EditCompanyModal = ({ show, onHide, company, reCall }) => {
    const [formData, setFormData] = useState({
        name: company?.name || "",
        address: company?.address || "",
        country: company?.country || "",
        phone: company?.phone || "",
        logo: company?.logo || "",
        sheetId: company?.sheetId || "",
        categorySheetId: company?.categorySheetId || "",
        creativeUrl: company?.creativeUrl || "",
        appIconUrl: company?.appIconUrl || "",
        id: company?._id
    });
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [imageUrl, setImageUrl] = useState(company?.logo || "");
    const fileInputRef = useRef(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setError((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
        const trimmedValue = name === 'sheetId' || name === 'categorySheetId' || name === 'creativeUrl' ? value.trim() : value;
        setFormData({
            ...formData,
            [name]: trimmedValue,
        });
    };

    const handleImageUpload = (e) => {
        const selectedFile = e.target.files[0];
        e.target.value = null;

        if (selectedFile) {
            const reader = new FileReader();
            if (selectedFile.type === "image/svg+xml") {
                reader.onloadend = () => {
                    setImageUrl(reader.result);
                };
                reader.readAsText(selectedFile);
            } else {
                reader.onloadend = () => {
                    setImageUrl(reader.result);
                };
                reader.readAsDataURL(selectedFile);
            }
            setFile(selectedFile);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setError({});

        if (!formData.name || !formData.address || !formData.country || !formData.sheetId || !formData.phone) {
            setError({
                name: !formData.name ? "Company Name is required" : '',
                address: !formData.address ? "Company Address is required" : '',
                country: !formData.country ? "Country is required" : '',
                sheetId: !formData.sheetId ? "Sheet Id is required" : '',
                phone: !formData.phone ? "Company Phone Number is required" : '',
                creativeUrl: !formData?.creativeUrl ? "Company Creative Url is required" : '',
            });
            return;
        }

        Swal.fire({
            title: "Are you sure?",
            text: "You want to create this company.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, create it!",
            cancelButtonText: "No, cancel",
            confirmButtonColor: "#1cc76d",
            cancelButtonColor: "#6C757D",
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Processing...",
                    text: "Please wait while we update the company information.",
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });
                setLoading(true);
                const updatedFormData = { ...formData };
                const uploadPromise = file ? uploadLogo(file) : Promise.resolve();
                uploadPromise
                    .then((logoUrl) => {
                        if (logoUrl) updatedFormData.logo = logoUrl;

                        return UpdateCompany(updatedFormData);
                    })
                    .then(() => {
                        Swal.close();

                        Swal.fire({
                            title: "Updated!",
                            text: "The company information has been updated successfully.",
                            icon: "success",
                            confirmButtonColor: "#28a745",
                            allowOutsideClick: false,
                        });
                        setFormData({
                            name: '',
                            address: '',
                            phone: '',
                            country: '',
                            logo: '',
                            sheetId: '',
                            categorySheetId: '',
                            ftd: '',
                            appId: ''
                        });
                        reCall();
                        onHide();
                    })
                    .catch((error) => {
                        Swal.close();
                        Swal.fire({
                            title: "Error!",
                            text: error?.response?.data?.error || 'An error occurred while updating the company information.',
                            icon: "error",
                            confirmButtonColor: "#dc3545",
                            allowOutsideClick: false,
                        });
                    })
                    .finally(() => setLoading(false));
            }
        });
    };

    const uploadLogo = (file) => {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('attachments', file);

            profileUser(formData)
                .then((response) => {
                    resolve(response?.data?.data?.imageUrl);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    return (
        <Modal show={show} onHide={onHide} centered size="lg" className='py-0 my-0' keyboard={false} backdrop="static">
            <Modal.Header className='border-0 mb-0 pb-0' closeButton />
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <h3 className='text-center text-box pb-2' style={{ color: "var(--primary-color)" }}>Edit Company</h3>
                    <Row className="my-3">
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="formCompanyName">
                                <Form.Label className="text-secondary">Company Name*</Form.Label>
                                <Form.Control
                                    size="lg"
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    isInvalid={!!error.name}
                                />
                                <Form.Control.Feedback type="invalid">{error.name}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="formCompanyAddress">
                                <Form.Label className="text-secondary">Company Address*</Form.Label>
                                <Form.Control
                                    size="lg"
                                    type="text"
                                    name="address"
                                    value={formData.address}
                                    onChange={handleInputChange}
                                    isInvalid={!!error.address}
                                />
                                <Form.Control.Feedback type="invalid">{error.address}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="formCountry" className='custom-input custome-phone'>
                                <Form.Label className="text-secondary">Country*</Form.Label>
                                <CountrySelect
                                    inputClassName="border-0"
                                    placeHolder="Select Country"
                                    value={formData.country}
                                    onChange={(country) => {
                                        setFormData({ ...formData, country: country.name });
                                        setError((prevErrors) => ({
                                            ...prevErrors,
                                            country: '',
                                        }));
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">{error.country}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Form.Group className='custome-phone'>
                                <Form.Label className="text-secondary">Company Phone Number*</Form.Label>
                                <PhoneInput
                                    inputClass='custome-phone py-4'
                                    country={'all'}
                                    placeholder='Enter Company phone number'
                                    value={formData.phone}
                                    onChange={(phone) => {
                                        setFormData({ ...formData, phone: phone });
                                        setError((prevErrors) => ({
                                            ...prevErrors,
                                            phone: '',
                                        }));
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">{error.phone}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="formCompanySheet">
                                <Form.Label className="text-secondary">Main Sheet Id*</Form.Label>
                                <Form.Control
                                    size="lg"
                                    type="text"
                                    name="sheetId"
                                    value={formData.sheetId}
                                    onChange={handleInputChange}
                                    isInvalid={!!error.sheetId}
                                />
                                <Form.Control.Feedback type="invalid">{error.sheetId}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="formCompanyCategory">
                                <Form.Label className="text-secondary">App Category Sheet Id</Form.Label>
                                <Form.Control
                                    size="lg"
                                    type="text"
                                    name="categorySheetId"
                                    value={formData.categorySheetId}
                                    onChange={handleInputChange}
                                // isInvalid={!!error.categorySheetId}
                                />
                                {/* <Form.Control.Feedback type="invalid">{error.categorySheetId}</Form.Control.Feedback> */}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="formCompanyCreativeUrl">
                                <Form.Label className="text-secondary">Company Creative Url*</Form.Label>
                                <Form.Control
                                    size="lg"
                                    type="text"
                                    name="creativeUrl"
                                    value={formData.creativeUrl}
                                    onChange={handleInputChange}
                                    isInvalid={!!error.creativeUrl}
                                />
                                <Form.Control.Feedback type="invalid">{error.creativeUrl}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="formCompanyappIconUrl">
                                <Form.Label className="text-secondary">Company App Icon Url</Form.Label>
                                <Form.Control
                                    size="lg"
                                    type="text"
                                    name="appIconUrl"
                                    value={formData.appIconUrl}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={12} lg={12}>
                            <Form.Group controlId="formCompanyLogo">
                                <Form.Label className="text-secondary">Company Logo</Form.Label>
                                <div className="d-flex align-items-center gap-2">
                                    {imageUrl && (
                                        <div className="card border-0 shadow" style={{ background: '#09332C' }}>
                                            <img
                                                src={imageUrl}
                                                alt="Company Logo"
                                                style={{ width: "100px", height: "100px", objectFit: "contain", marginRight: "10px" }}
                                            />
                                        </div>
                                    )}
                                    <Button
                                        variant="secondary"
                                        style={{ background: "var(--bg-ltr)" }}
                                        disabled={loading}
                                        onClick={() => fileInputRef.current.click()}
                                    >
                                        {imageUrl ? "Change Logo" : "Upload Logo"}
                                    </Button>
                                </div>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    accept=".png, .jpg, .jpeg"
                                    onChange={handleImageUpload}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className="d-flex justify-content-between pt-2 mt-3">
                        <Button onClick={onHide} className='bg-secondary border-0 px-4 py-2' disabled={loading}>Cancel</Button>
                        <Button type="submit" className='bg-primary-ltr border-0 px-4 py-2 submit-btn-hover' disabled={loading}>
                            {loading ? 'Saving...' : 'Save'}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default EditCompanyModal;
