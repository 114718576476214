import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

const PdfFooter = ({ showImage }) => {
    const location = useLocation();

    const [pageNumbers, setPageNumbers] = useState(() => {
        const savedPageNumber = JSON.parse(localStorage.getItem("lastPageNumber"));
        return savedPageNumber || 1;
    });

    useEffect(() => {
        if (showImage) {
            const newPageNumber = pageNumbers + 1;
            setPageNumbers(newPageNumber);
            localStorage.setItem('lastPageNumber', JSON.stringify(newPageNumber));
            console.log("Number", newPageNumber);
        }
    }, [location.pathname]);

    return (
        // showImage && (
        <Container className="px-5" fluid>
            <div className="d-flex justify-content-between align-items-center py-4">
                <div>
                    <p style={{ fontSize: '12px', fontWeight: '400', color: '#00000080' }} className='mb-0 pb-0'>
                        Copyright © 2024 KyPI
                    </p>
                </div>
                <div>
                    <p style={{ fontSize: '16px', fontWeight: '600', color: '#000000' }} className='mb-0 pb-0'>
                        0{pageNumbers}
                    </p>
                </div>
            </div>
        </Container>
        // )
    );
};

export default PdfFooter;
