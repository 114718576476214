import { Link } from 'react-router-dom'

const NotFound = () => {
    return (
        <div className='d-flex justify-content-center flex-column align-items-center' style={{
            height: "100vh", background: "var(--bg-ltr)"
        }}>
            <h1 className="display-1  text-white">404</h1>
            <Link to="/" className='btn btn-warning text-white ' >Go Home</Link>
        </div>
    )
}

export default NotFound
