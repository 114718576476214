import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./utils/AuthContext";
import "./global.css";
import "rsuite/dist/rsuite.min.css";
import 'react-loading-skeleton/dist/skeleton.css'
import "react-toggle/style.css";
import "react-country-state-city/dist/react-country-state-city.css";
import 'react-phone-input-2/lib/style.css'
import "bootstrap/dist/css/bootstrap.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import { ModalProvider } from "./utils/Modal";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <AuthProvider>
      <ModalProvider>
      <App />
      </ModalProvider>
    </AuthProvider>
  </BrowserRouter>
);
