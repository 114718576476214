import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'

const FTDUniqueSection = ({ formData, valueOptionsFtd, attributeOptions, handleAttributeChangeFtd, setFormData }) => {
    console.log("Form Data label", formData)
    return (
        <div className="mt-3">
            <p className="form-label text-secondary pb-2">Select Main Event</p>
            <div className="px-2">
                <Row className="border py-2 gy-2">
                    <Col sm={6}>
                        <Form.Group controlId={`FTD label`}>
                            <Form.Label className="text-secondary mb-0">Label</Form.Label>
                            <Form.Control
                                type="text"
                                name={`ftd-label`}
                                value={formData?.ftdUnique?.label}
                                onChange={(e) => {
                                    console.log("Label changed:", e.target.value);
                                    setFormData(prev => ({
                                        ...prev,
                                        ftdUnique: {
                                            ...prev.ftdUnique,
                                            label: e.target.value
                                        }
                                    }));
                                }}
                            />

                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group controlId="formAttribute">
                            <Form.Label className="text-secondary mb-0">Select Column name</Form.Label>
                            <Form.Select value={formData?.ftdUnique?.name} onChange={handleAttributeChangeFtd}>
                                <option value="">Select Column</option>
                                {attributeOptions?.map(attr => (
                                    <option key={attr.value} value={attr.value}>{attr.label}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group controlId="formValue">
                            <Form.Label className="text-secondary mb-0">Select Column value</Form.Label>
                            <Form.Select value={formData?.ftdUnique?.value} onChange={e => setFormData(prev => ({
                                ...prev, ftdUnique: { ...prev?.ftdUnique, value: e.target.value }
                            }))} disabled={!formData?.ftdUnique?.name}>
                                <option value="">Select Value</option>
                                {valueOptionsFtd?.map(val => (
                                    <option key={val.value} value={val.value}>{val.label}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group controlId={`selectOption-metric`}>
                            <Form.Label className="text-secondary mb-0"> FTD Option</Form.Label>
                            <Form.Select
                                value={formData?.ftdUnique?.select}
                                onChange={(e) => setFormData(prev => ({
                                    ...prev,
                                    ftdUnique: {
                                        ...prev.ftdUnique,
                                        select: e.target.value
                                    }
                                }))}>
                                <option value="count">Count</option>
                                <option value="sum">Sum</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default FTDUniqueSection
