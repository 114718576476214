import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'

const MetricSelection = ({ formData, attributeOptions, matricValueOptions, handleSelectMatricsChange, selectedBudget, company, setFormData }) => {
    console.log("FormData at render:", formData.selectedMatrics);
    const handleSelectChangeOption = (index, value) => {
        const updatedMatrics = [...formData.selectedMatrics];
        updatedMatrics[index].select = value;
        setFormData(prev => ({
            ...prev,
            selectedMatrics: updatedMatrics,
        }));
    };

    const handleLabelChange = (index, value) => {
        const updatedMatrics = [...formData.selectedMatrics];
        updatedMatrics[index].label = value;
        setFormData(prev => ({
            ...prev,
            selectedMatrics: updatedMatrics,
        }));
    };

    return (
        <div className="mt-3">
            <p className="text-secondary form-label mb-0 pb-2">Top Metrics</p>
            {formData?.selectedMatrics.map((metric, index) => (
                <div className="px-2 py-2" key={index}>
                    <Row className="border py-2 gy-2 align-items-center" key={index}>
                        <Form.Label className=" mb-0">Metric {index + 1}</Form.Label>
                        <Col sm={6}>
                            <Form.Group controlId={`Matric-${index}`}>
                                <Form.Label className="text-secondary mb-0"> Label {index + 1}</Form.Label>
                                <Form.Control
                                    type='text'
                                    name={`metric-${index}-label`}
                                    value={formData.selectedMatrics[index].label}
                                    onChange={e => handleLabelChange(index, e.target.value)}
                                    placeholder={`Label-${index + 1}`}
                                />
                            </Form.Group>
                        </Col>
                        {index === 0 ? (
                            <>
                                <Col sm={6}>
                                    <p className="text-secondary form-label  mb-0">Label {index + 1} Value</p>
                                    {selectedBudget === 'budget' ? (
                                        <>
                                            {`${formData?.budget?.name}[${formData?.budget?.value}] * ${formData?.budget?.count} ${formData?.budget?.currency}`}
                                        </>
                                    ) : (
                                        <>
                                            {`(${company?.budget2?.name}[${company?.budget2?.value}] * ${company?.budget2?.count}) + (${company?.budget2?.name2}[${company?.budget2?.value2}] * ${company?.budget2?.count2}) ${company?.budget2?.currency}`}
                                        </>
                                    )}
                                </Col>
                            </>
                        ) : (
                            <>
                                <Col sm={6}>
                                    <Form.Group controlId={`formAttribute-${index}`}>
                                        <Form.Label className="text-secondary  mb-0"> Label {index + 1} Column</Form.Label>
                                        <Form.Select value={formData?.selectedMatrics[index]?.name} onChange={e => handleSelectMatricsChange(index, 'name', e.target.value)}>
                                            <option value="">Select Column</option>
                                            {attributeOptions.map(attr => (
                                                <option key={attr.value} value={attr.value}>{attr.label}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group controlId={`formValue-${index}`}>
                                        <Form.Label className="text-secondary  mb-0"> Label {index + 1} Value</Form.Label>
                                        <Form.Select value={formData?.selectedMatrics[index]?.value} onChange={e => handleSelectMatricsChange(index, 'value', e.target.value)} disabled={!metric.name}>
                                            <option value="">Select Value</option>
                                            {matricValueOptions[index]?.map(val => (
                                                <option key={val.value} value={val.value}>{val.label}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group controlId={`selectOption-${index}`}>
                                        <Form.Label className="text-secondary mb-0"> Label {index + 1} Option</Form.Label>
                                        <Form.Select value={formData?.selectedMatrics[index]?.select} onChange={e => handleSelectChangeOption(index, e.target.value)}>
                                            <option value="count">Count</option>
                                            <option value="sum">Sum</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </>
                        )}
                    </Row>
                </div>
            ))}
        </div>
    )
}

export default MetricSelection
